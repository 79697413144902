var mod = angular.module('Solinox.directives', []);

mod.directive('calcPercent', function() {
    return {
	restrict: 'E',
	scope: {
	    field: '=',
	    values: '=',
	    avForm: '='
	},
	templateUrl: 'calcPercentField.html',
	controller: 'CalcPercentCtrl'
    }
});

mod.directive('calcCurrency', function() {
    return {
	restrict: 'E',
	scope: {
	    field: '=',
	    values: '=',
	    avForm: '='
	},
	templateUrl: 'calcCurrencyField.html'
    }
});

mod.directive('calcCurrencyQtd', function() {
    return {
	restrict: 'E',
	scope: {
	    field: '=',
	    values: '='
	},
	templateUrl: 'calcCurrencyQtdField.html'
    }
});

mod.directive('itemFixedTitle', function() {
    return {
	restrict: 'E',
	templateUrl: 'itemFixedTitle.html'
    }
});

mod.directive('cotacaoCheckbox', function() {
    return {
	restrict: 'E',
	link: function(scope,elem,attrs) {
	    var html = "<input type='checkbox' />";
            elem.html(html);
	    elem.on('change',function(){
		scope.item.checked = elem.children()[0].checked;
		scope.$apply();
	    });
	    if(scope.lote.cbList==undefined)
		scope.lote.cbList = [];
	    scope.lote.cbList.push(elem);
	}
    }
});

mod.directive('arquivoTypeahead', function($timeout) {
    return {
	restrict: 'A',
	link: function(scope,rootElem,attrs) {

	    scope.$on('$typeahead.select',  function(event, value, index, elem){
		
		rootElem[0].blur();
		scope.anexarArquivo(value.docId);

		// limpa campo, mas espera meio segundo para concluir o blur 
		// e evitar que apareça o novo typeahead 
		$timeout(function(){
		    scope.anexar = "";
		},500);
	    });
	    
	}
    }
    
});


mod.directive('comentarioPopover', function($popover,Documents,$alert,$q) {
    return {
	restrict: 'A',
	scope: {
	    translations: '=',
	    refDocId:'=',
	    comentarios:'=',
	    action:'&'
	},
	link: function(scope,element,attrs) {
	    scope.comentario = {
		attrs: {
		    fieldName: 'comentario',
		    required: 'required',
		    size: 65535,
		    label: 'Comentário'
		}
	    }

	    scope.values = {};

	    scope.envia = function($hide,popoverForm) {

		if(!popoverForm.$valid)
		{
		    popoverForm.submitted = true;
		    return;
		}

		$hide();
		var savingAlert = $alert({
		    animation: 'am-slide-top',
		    placement: 'top-right',
		    title: scope.translations['savingTag']+'...',
		    type: 'info',
		    show: true,
		    container: 'body',
		    dismissable: true
		});

		var taskList = [];
		var saveTask = $q.defer();
		taskList.push(saveTask.promise);

		Documents.save({
		    path: attrs.path,
		    refDocId: scope.refDocId
		}, scope.values, function(doc){
		    saveTask.resolve();
		    if(attrs.comentarios!=undefined && scope.comentarios)
			scope.comentarios.push(doc.attrs);
		    scope.values = {};
		}, function(resp){
		    saveTask.reject();
		    var error = $alert({
			animation: 'am-slide-top',
			placement: 'top-right',
			title: scope.translations['alertErrorTag']+':',
			content: resp.data.statusMessage,
			type: 'danger',
			show: true,
			container: 'body',
			duration: 5,
			dismissable: true
		    });
		});
		
		if(attrs.action!=undefined)
		{
		    var actionTask = $q.defer();
		    taskList.push(actionTask.promise);
		    scope.action({task:actionTask});
		}

		$q.all(taskList).then(function() {
		    savingAlert.hide();
		}, function() {
		    savingAlert.hide();
		});
	    }

	    var options = {
		content: attrs.comentarioPopover,
		template: "comentario.html",
		autoClose: 1,
		scope: scope
	    }
	    if(attrs.placement)
		options.placement = attrs.placement;
	    var popover = $popover(element, options);
	}
    }

});

mod.directive('textDynReq',function() {
    return {
	restrict: "E",
	scope: {
	    field: '=',
	    values: '=',
	    avForm: '='
	},
	templateUrl: 'textDynReqField.html'
    };
});
