var mod = angular.module('avalanche',['avalanche.ctrls','avalanche.services','avalanche.directives']);

mod.filter('mysqlDatetimeToISO', function() {
  return function(badTime) {
    var goodTime = badTime.replace(/(.+) (.+)/, "$1T$2");
    return goodTime;
  };
});

mod.filter('cut', function () {
    return function (value, wordwise, max, tail) {
        if (!value) return '';
	
        max = parseInt(max, 10);
        if (!max) return value;
        if (value.length <= max) return value;
	
        value = value.substr(0, max);
        if (wordwise) {
            var lastspace = value.lastIndexOf(' ');
            if (lastspace != -1) {
                value = value.substr(0, lastspace);
            }
        }
	
        return value + (tail || ' …');
    };
});
