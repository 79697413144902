var app = angular.module('Solinox', [
    'ngAnimate',
    'ngSanitize',
    'ui.router',
    'mgcrea.ngStrap',
    'kcd.directives',
    'uz.mailto',
    'pascalprecht.translate',
    'as.sortable',
    'Solinox.filters',
    'Solinox.controllers.Main',
    'Solinox.services',
    'Solinox.directives',
    'avalanche',
    'calc.percent.typeahead'
]);

app.config(function($urlRouterProvider,$locationProvider,$translateProvider,$httpProvider,avalancheConfig) {
    $urlRouterProvider.deferIntercept();

    $locationProvider.html5Mode(false);

    $translateProvider.useLoader('$translatePartialLoader', {
	urlTemplate: avalancheConfig.backendAddress+'/labels/{lang}/{part}'
    });

    $translateProvider.preferredLanguage('pt_BR');
    $translateProvider.useSanitizeValueStrategy('escaped');

});


