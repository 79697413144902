var mod = angular.module('avalanche.directives', []);

mod.directive('avForm', function(avalancheConfig) {
    return {
	restrict: 'E',
	templateUrl: 'avForm.html',
	link: function(scope,element,attr) {
	    scope.datepickerFormat = avalancheConfig.datepickerFormat;
	    scope.timepickerFormat = avalancheConfig.timepickerFormat;
	    scope.avFormSubmit = function() {
		if(scope.avForm.$valid) {
		    // commit normally
		    if(attr.submit!=undefined)
			scope.$eval(attr.submit);
		} else {
		    scope.avForm.submitted = true;
		}
	    }
	}
    }
});

mod.directive('avField', function() {
    return {
	restrict: 'E',
	templateUrl: 'avField.html'
    }
});

mod.directive('avText',function() {
    return {
	restrict: "E",
	scope: {
	    field: '=',
	    values: '=',
	    avForm: '=',
	    fieldIndex: '='
	},
	templateUrl: 'avTextField.html'
    };
});

mod.directive('avTextArea',function() {
    return {
	restrict: "E",
	scope: {
	    field: '=',
	    values: '=',
	    avForm: '=',
	    fieldIndex: '='
	},
	templateUrl: 'avTextAreaField.html'
    };
});

mod.directive('avInteger',function() {
    return {
	restrict: "E",
	scope: {
	    field: '=',
	    values: '=',
	    avForm: '=',
	    fieldIndex: '='
	},
	templateUrl: 'avIntegerField.html'
    };
});

mod.directive('avCurrency',function() {
    return {
	restrict: "E",
	scope: {
	    field: '=',
	    values: '=',
	    avForm: '=',
	    fieldIndex: '='
	},
	templateUrl: 'avCurrencyField.html'
    };
});

mod.directive('avPercent',function() {
    return {
	restrict: "E",
	scope: {
	    field: '=',
	    values: '=',
	    avForm: '=',
	    fieldIndex: '='
	},
	templateUrl: 'avPercentField.html'
    };
});

mod.directive('avCurrencySymbol',function($locale) {
    return {
	restrict: "EA",
        template: $locale.NUMBER_FORMATS.CURRENCY_SYM
    };
});

mod.directive('avBooleanInput', function() {
    return {
	require: 'ngModel',
	link: function(scope, element, attrs, ngModel) {
	    ngModel.$formatters.push(function(value) {
		return (value==1)?true:false;
	    });

            ngModel.$parsers.push(function (viewValue) {
		return viewValue?1:0
            });

	}
    };
});

mod.directive('avIntegerInput', function() {
    return {
	require: 'ngModel',
	link: function(scope, element, attrs, ngModel) {
	    ngModel.$formatters.push(function(value) {
		return parseInt(value);
	    });
	}
    };
});

mod.directive('avFloatInput', ['$filter', '$locale', function ($filter, $locale) {
    return {
        require: 'ngModel',
        /*scope: {
            min: '=min',
            max: '=max',
            ngRequired: '=ngRequired'
        },*/
        link: function (scope, element, attrs, ngModel) {

            function decimalRex(dChar) {
                return RegExp("\\d|\\-|\\" + dChar, 'g');
            }

            function clearRex(dChar) {
                return RegExp("\\-{0,1}((\\" + dChar + ")|([0-9]{1,}\\" + dChar + "?))&?[0-9]{0,2}", 'g');
            }

            function clearValue(value) {
                value = String(value);
                var dSeparator = $locale.NUMBER_FORMATS.DECIMAL_SEP;
                var cleared = null;

                if(RegExp("^-[\\s]*$", 'g').test(value)) {
                    value = "-0";
                }

                if(decimalRex(dSeparator).test(value))
                {
                    cleared = value.match(decimalRex(dSeparator))
                        .join("").match(clearRex(dSeparator));
                    cleared = cleared ? cleared[0].replace(dSeparator, ".") : null;
                }

                return cleared;
            }

            ngModel.$parsers.push(function (viewValue) {
                return clearValue(viewValue);
            });

            element.on("blur", function () {
                element.val($filter('number')(ngModel.$modelValue, 2));
            });

            ngModel.$formatters.unshift(function (value) {
		if(value != undefined && value !== null && value !== '')
                    return $filter('number')(value, 2);
            });

            scope.$watch(function () {
                return ngModel.$modelValue;
            }, function (newValue, oldValue) {
                runValidations(newValue);
            })

            function runValidations(cVal) {
                if (isNaN(cVal)) {
                    return
                }
                if (typeof scope.min !== 'undefined') {
                    var min = parseFloat(scope.min)
                    ngModel.$setValidity('min', cVal >= min)
                }
                if (typeof scope.max !== 'undefined') {
                    var max = parseFloat(scope.max)
                    ngModel.$setValidity('max', cVal <= max)
                }
            }
        }
    }
}]);


mod.directive('avSelect',function() {
    return {
	restrict: "E",
	scope: {
	    field: '=',
	    values: '=',
	    fieldList: '=',
	    avForm: '=',
	    fieldIndex: '='
	},
	templateUrl: 'avSelectField.html'
    };
});

mod.directive('avTargetField', function(Documents) {
    return {
        require: 'ngModel',
        link: function (scope, element, attrs, ngModel) {
	    scope.firstBind = true;
	    if(attrs.avTargetField!="")
	    {
		scope.$watch(function () {
                    return ngModel.$modelValue
		}, function (newValue, oldValue) {
		    if(scope.firstBind)
		    {
			scope.firstBind = false;
			return;
		    }
		    for(var i=0; i<scope.fieldList.length; i++)
		    {
			if(scope.fieldList[i].attrs.fieldName==attrs.avTargetField)
			{
			    if(newValue==undefined || newValue=="")
			    {
				scope.fieldList[i].children = [];
				return;
			    }
			    var data = Documents.get({
				path: scope.values.path,
				fieldName: attrs.avTargetField,
				sourceId: newValue
			    },function() {
				scope.fieldList[i].children = data.children[0].children;
			    });
			    return;
			}
		    }

		});
	    }
	}
    };
});

mod.directive('avObjectList',function() {
    return {
	restrict: "E",
	templateUrl: 'avObjectListField.html',
	scope: {
	    field: '=',
	    values: '=',
	    avForm: '=',
	    dateFormat: '@',
	    timeFormat: '@',
	    aSortableListeners: '='
	},
	link: function(scope,element,attr) {
	    scope.insert = function($event) {
		$event.preventDefault();
		eval('scope.values.'+scope.field.attrs.fieldName+'.push({})');
	    }
	    scope.remove = function($index) {
		eval('scope.values.'+scope.field.attrs.fieldName+'.splice($index,1)');
		scope.avForm.$setDirty();
	    }

	    if(scope.aSortableListeners==undefined)
		scope.aSortableListeners = {};
	    scope.aSortableListeners.accept = function (sourceItemHandleScope, destSortableScope) {
		return sourceItemHandleScope.itemScope.sortableScope.$id === destSortableScope.$id;
	    }
	    scope.aSortableListeners.orderChanged = function(event) {
		scope.avForm.$setDirty();
	    }  
	    
	}
    };
});

mod.directive('avDateTime',function() {
    return {
	restrict: "E",
	scope: {
	    field: '=',
	    values: '=',
	    dateFormat: '@',
	    timeFormat: '@',
	    avForm: '=',
	    fieldIndex: '='
	},
	templateUrl: 'avDateTimeField.html'
    };
});

mod.directive('avDate',function() {
    return {
	restrict: "E",
	scope: {
	    field: '=',
	    values: '=',
	    dateFormat: '@',
	    avForm: '=',
	    fieldIndex: '='
	},
	templateUrl: 'avDateField.html'
    };
});

mod.directive('avDatepickerCtrls', function($filter) {
    return {
	restrict: 'A',
	link: function(scope,element,attr) {
	    scope.today = function() {
		var today = new Date();
		scope.busca.date = $filter('date')(today, attr['modelDateFormat']);
		element[0].blur();

	    }

	    scope.clear = function() {
		scope.busca.date = undefined;
		element[0].blur();
	    }
	}
    }
});

mod.directive('avFileList',function(Upload,avalancheConfig,$alert) {
    return {
	restrict: "E",
	scope: {
	    field: '=',
	    values: '=',
	    translations: '=',
	    avForm: '='
	},
	templateUrl: 'avFileListField.html',
	link: function(scope,element,attr) {
	    scope.thumbUrl = avalancheConfig.backendAddress+'/thumb/';
	    scope.removeSelectedDisabled = true;

	    var checkRemoveSelected = function() {
		var values = eval('scope.values.'+scope.field.attrs.fieldName);
		for(var i=0; i<values.length; i++)
		    if(values[i].active)
			return false;
		return true;
	    }

	    scope.$watch('values.'+scope.field.attrs.fieldName,function(oldValue, newValue){
		if(oldValue==newValue)
		    return;
		scope.avForm.$setDirty();
	    },true);

	    scope.remove = function($index,checkSelected) {
		var values = eval('scope.values.'+scope.field.attrs.fieldName);

		if(checkSelected==undefined || checkSelected)
		    scope.removeSelectedDisabled = checkRemoveSelected();
		values.splice($index,1);			  
	    }

	    scope.removeSelected = function() {
		var values = eval('scope.values.'+scope.field.attrs.fieldName);
		for(var i=values.length-1; i >= 0; i--)
		    if(values[i].active)
			scope.remove(i,false);
		scope.removeSelectedDisabled = true;
	    }

	    scope.selectAll = function() {
		var values = eval('scope.values.'+scope.field.attrs.fieldName);

		// verifica se todos estão selecionados
		var allSelected = true;
		for(var i=0; i<values.length && allSelected; i++)
		    if(!values[i].active)
			allSelected = false;

		// se todos estiverem selecionados remove seleção
		// senão, seleciona todos
		for(var i=0; i<values.length; i++)
		    values[i].active = (allSelected)?false:true;

		scope.removeSelectedDisabled = (allSelected)?true:false;
	    }

	    scope.select = function(file,$event) {
		$event.preventDefault();
		file.active = !file.active;
		scope.removeSelectedDisabled = (file.active)?false:checkRemoveSelected();
	    }

	    scope.upload = function(files) {
		var maxFiles = scope.field.attrs.maxFiles;
		var values = eval('scope.values.'+scope.field.attrs.fieldName);
		if(values==null)
		    values = [];
		if(maxFiles==undefined)
		    maxFiles = 0;
		if (files && files.length) {
		    if(maxFiles>0)
		    {
			if(values.length >= maxFiles)
			    return;

			if(files.length+values.length>maxFiles)
			    files.splice(maxFiles-values.length,files.length+values.length-maxFiles);
		    }
		    alertScope = scope.$new();
		    alertScope.title = scope.translations['uploadingTag']+'...';
		    var uploadingAlert = $alert({
			animation: 'am-slide-top',
			placement: 'top-right',
			type: 'info',
			show: true,
			container: 'body',
			dismissable: true,
			scope: alertScope
		    });
		    Upload.upload({
			url: avalancheConfig.backendAddress+'/upload',
			fields: {
			    'path': avalancheConfig.path.files
			},
			file: files,
			fileFormDataName: 'files[]'
		    }).progress(function (evt) {
			var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
			alertScope.title = scope.translations['uploadingTag']+'... '+progressPercentage + '%';
			//console.log('progress: ' + progressPercentage + '% ' + evt.config.file.name);
		    }).success(function (data, status, headers, config) {
			uploadingAlert.hide();
			alertScope.$destroy();
			eval('scope.values.'+scope.field.attrs.fieldName+'=values.concat(data)');
		    }).error(function (data, status, headers, config) {
			//console.log('error status: ' + status);
			uploadingAlert.hide();
			alertScope.$destroy();
			var error = $alert({
			    animation: 'am-slide-top',
			    placement: 'top-right',
			    title: 'ERRO: ',
			    content: data.statusMessage,
			    type: 'warning',
			    show: true,
			    container: 'body',
			    duration: 5,
			    dismissable: true
			});
		    })
		}
	    };
	}
    };
});

mod.directive('avComposedField', function() {
    return {
        require: 'ngModel',
        link: function (scope, element, attrs, ngModel) {
	    var scopeField = eval('scope.'+attrs.avComposedField);
	    if(scopeField==undefined)
	    {
		scopeField = { fields: [], maxlength: 0};
		eval('scope.'+attrs.avComposedField+'=scopeField');
	    }

	    var id = scopeField.fields.length;
	    scopeField.fields.push("");
	    var strPos = scopeField.maxlength;
	    scopeField.maxlength += parseInt(attrs.maxlength);

            ngModel.$parsers.push(function (viewValue) {
		scopeField.fields[id] = viewValue;
		return value = scopeField.fields.join("");
            });

            ngModel.$formatters.unshift(function (value) {
		if(typeof(value) != "string" || value.length==0)
		    return scopeField.fields[id];

		if(value.length==scopeField.maxlength)
		    scopeField.fields[id] = value.substr(strPos,attrs.maxlength);
		return scopeField.fields[id];
            });

	    element.bind('keyup',function(){
		if(element.val().length==attrs.maxlength && id < scopeField.fields.length-1)
		    element.next()[0].select();
	    });

	}
    };
});


mod.directive('avCnpjField', function(CalcDigitosPosicoes) {

    var valida_cnpj = function(valor) {
	// Garante que o valor é uma string
	valor = valor.toString();

	// Remove caracteres inválidos do valor
	valor = valor.replace(/[^0-9]/g, '');

	// O valor original
	var cnpj_original = valor;

	// Captura os primeiros 12 números do CNPJ
	var primeiros_numeros_cnpj = valor.substr( 0, 12 );

	// Faz o primeiro cálculo
	var primeiro_calculo = CalcDigitosPosicoes( primeiros_numeros_cnpj, 5 );

	// O segundo cálculo é a mesma coisa do primeiro, porém, começa na posição 6
	var segundo_calculo = CalcDigitosPosicoes( primeiro_calculo, 6 );

	// Concatena o segundo dígito ao CNPJ
	var cnpj = segundo_calculo;

	// Verifica se o CNPJ gerado é idêntico ao enviado
	if ( cnpj === cnpj_original ) {
            return true;
	}

	// Retorna falso por padrão
	return false;

    } // valida_cnpj

    return {
        require: 'ngModel',
        link: function (scope, element, attrs, ngModel) {
	    var scopeField = eval('scope.'+attrs.avComposedField);

          //For DOM -> model validation
            ngModel.$parsers.push(function(value) {
		ngModel.$setValidity('invalidCnpj', valida_cnpj(value));
		return value;
            });

            //For model -> DOM validation
            ngModel.$formatters.push(function(value) {
		if(value)
		    ngModel.$setValidity('invalidCnpj', valida_cnpj(value));
		return value;
            });

	}
    }
});

mod.directive('avCnpj', function() {
    return {
	restrict: "E",
	scope: {
	    field: '=',
	    values: '=',
	    avForm: '='
	},
	templateUrl: 'avCnpjField.html',
    };
});

mod.directive('avCep', function() {
    return {
	restrict: "E",
	scope: {
	    field: '=',
	    values: '=',
	    avForm: '='
	},
	templateUrl: 'avCepField.html'
    };
});

mod.directive('avCepField', function(Cep, avalancheConfig) {
    return {
        require: 'ngModel',
        link: function (scope, element, attrs, ngModel) {
	    scope.firstBind = true;
            scope.$watch(function () {
                return ngModel.$modelValue
            }, function (newValue, oldValue) {
		if(scope.firstBind)
		{
		    scope.firstBind = false;
		    return;
		}

		if(typeof(newValue)!="string" || newValue.length!=8)
		    return;

		var data = Cep.get({cep:newValue},function(){
		    eval('scope.values.'+avalancheConfig.cepConfig.estado+'="'+data.uf_codigo+'"');
		    eval('scope.values.'+avalancheConfig.cepConfig.cidade+'="'+data.cidade_codigo+'"');
		    eval('scope.values.'+avalancheConfig.cepConfig.logradouro+'="'+data.endereco_logradouro+'"');
		    eval('scope.values.'+avalancheConfig.cepConfig.bairro+'="'+data.bairro_descricao+'"');
		});

            })
	}
    };
});

mod.directive('avEmail', function() {
    return {
	restrict: "E",
	scope: {
	    field: '=',
	    values: '=',
	    avForm: '='
	},
	templateUrl: 'avEmailField.html'
    };
});

mod.directive('avCheckbox', function() {
    return {
	restrict: "E",
	scope: {
	    field: '=',
	    values: '='
	},
	templateUrl: 'avCheckboxField.html'
    };
});

mod.directive('avLoginForm',function() {
    return {
	restrict: 'E',
	templateUrl: 'avLogin.html',
	link: function(scope,elem,attrs) {
	}
    }
});

mod.directive('avUploader', function () {
    return {
	restrict: 'AE',
	transclude: true,
	template: "<input type='file' name='content' files-model='eventoArquivo.content' style='display:none'><span ng-transclude></span>",
	link: function(scope, element, attrs) {
	    element.ready(function(){
		// recupera input
		var input = element.find("input");

		// onchange do input deve submiter o arquivo
		input.bind('change',function(){
		    scope.EnviaEvento(scope.eventoArquivo);
		});

	        element.bind("click", function(){
		    input[0].click();
		});
            });
	}
    }
});

mod.directive('avTooltip', function($tooltip) {
    return {
	restrict: 'A',
	link: function(scope,element,attr) {
	    var options = {};
	    var prefix = 'avTooltip';
            angular.forEach([ 'template', 'contentTemplate', 'placement', 'container', 'delay', 'trigger', 'html', 'animation', 'backdropAnimation', 'type', 'customClass', 'id' ], function(key) {
		var cKey = prefix+key.charAt(0).toUpperCase()+key.substring(1);
		if (angular.isDefined(attr[cKey]))
		    options[key] = attr[cKey];
            });
            var falseValueRegExp = /^(false|0|)$/i;
            angular.forEach([ 'html', 'container' ], function(key) {
		var cKey = prefix+key.charAt(0).toUpperCase()+key.substring(1);
		if (angular.isDefined(attr[cKey]) && falseValueRegExp.test(attr[cKey]))
		    options[key] = false;
            });
            var dataTarget = element.attr('av-tooltip-target');
            if (angular.isDefined(dataTarget)) {
		if (falseValueRegExp.test(dataTarget))
		    options.target = false;
		else
		    options.target = dataTarget;
            }
	    options.title = attr.avTooltip;

	    var tooltip = $tooltip(element, options);

	    scope.$on('tooltip.show',  function(event, elem){
		tooltip.hide();
		tooltip.setEnabled(false);
	    });

	    scope.$on('tooltip.hide',  function(event, elem){
		tooltip.setEnabled(true);
	    });

	}
    }
});

mod.directive('avPagination', function($anchorScroll) {
    return {
	restrict: "E",
	templateUrl: "avPagination.html",
        link: function (scope, element, attrs) {
	    scope.setOffset = function(offset) {
		$anchorScroll.yOffset = 0;
		$anchorScroll();
		scope.$emit('av.pagination.offset',offset);
	    }

            attrs.source && scope.$watch(attrs.source, function(newValue, oldValue) {
		if(newValue==undefined || newValue.totalResults==undefined)
		{
		    scope.showPagination = false;
		    return;
		}

		scope.pages = [];

		var navStart = 0;
		var offset = parseInt(eval('scope.'+attrs.offset));
		var limit = parseInt(eval('scope.'+attrs.limit));
		var totalResults = newValue.totalResults;
		scope.nextOffset=offset+limit;
		scope.prevOffset=offset-limit;
		scope.disableNext = (totalResults-offset<=limit);
		scope.disablePrev = (offset<limit);
		scope.showPagination = (totalResults>limit);

		if(limit<=0 || totalResults < limit)
		    return;

		if(offset>=limit*6)
		    navStart = offset - limit*5;

		for(var i=navStart;
		    i <= navStart+limit*9 && i < totalResults;
		    i += limit)
		{
		    var page = { value: (i/limit+1), selected: false};
		    if(i == offset)
			page.selected = true;
		    page.offset = i;

		    scope.pages.push(page);
 		}
	    }, true);
	}
    };
});

mod.directive('avFileViewer', function($modal,$window,avalancheConfig) {
    return {
	restrict: "A",
	scope: { doc: '=' },
        link: function (scope, element, attrs) {
	    element.bind('click',function(){
		scope.fileSrc = avalancheConfig.backendAddress+'/file/'+scope.doc.docId;
		if((scope.doc.contentType=='application/pdf' ||
		    scope.doc.contentType=='text/html' ||
		    scope.doc.contentType.indexOf('image')==0) &&
		  !bowser.msie) {
		    // open viewer only for pdf and images
		    var options = {
			contentTemplate: 'avFileViewer.html',
			template: 'avFileViewerModal.html',
			scope: scope,
			//animation: 'am-fade-and-scale' //iframe resizing
		    }
		    if(attrs.contentTemplate)
			options.contentTemplate = attrs.contentTemplate;
		    if(attrs.container)
			options.container = attrs.container;
		    var modalInstance = $modal(options);
		} else {
		    $window.open(scope.fileSrc,'_blank');
		}

	    });
	}
    };
});

mod.directive('avMdiFileIcon', function() {
    return {
	restrict: "E",
	scope: { contentType: '=' },
	template: "<i class='mdi file-icon' ng-class='getIconClass()'>",
        link: function (scope, element, attrs) {
	    scope.getIconClass = function () {
		switch(scope.contentType)
		{
		    // documents
		case 'text/plain':
		    return 'mdi-file-document';

		    // spreadsheet
		case 'application/vnd.ms-excel':
		    return 'mdi-file-excel';

		    // word
		case 'application/msword':
		    return 'mdi-file-word';

		    // images
		case 'image/gif':
		case 'image/png':
		case 'image/jpeg':
		case 'image/pjpeg':
		    return 'mdi-file-image';

		    // music
		case 'audio/mpeg':
		case 'audio/x-pn-realaudio':
		case 'audio/x-wav':
		    return 'mdi-file-music';

		    // pdf
		case 'application/pdf':
		    return 'mdi-file-pdf';
		default:
		    return 'mdi-file';
		}
	    }
	}
    };
});

mod.directive('avConfirmPopover', function($popover) {
    return {
	restrict: 'A',
	scope: {
	    action: '&'
	},
	link: function(scope,element,attrs) {
	    scope.confirm = function($event,$hide) {
		$event.preventDefault();
		$hide();
		scope.action();
	    }
	    var options = {
		content: attrs.avConfirmPopover,
		template: 'avConfirmPopover.html',
		autoClose: 1,
		scope: scope
	    }
	    if(attrs.placement)
		options.placement = attrs.placement;
	    var popover = $popover(element, options);
	}
    }

});

mod.directive('avBody', function() {
    return {
	restrict: 'A',
	controller: function($scope, $rootScope){
	    $rootScope.$on('$stateChangeSuccess',
			   function(event, toState, toParams, fromState, fromParams ){
			       $scope.stateClass = toState.name.replace(/\./g,'-');
			   });
	},
	link: function(scope,element,attrs) {
	    scope.$watch('stateClass',function(newValue,oldValue) {
		if(oldValue)
		    element.removeClass(oldValue);
		if(newValue)
		    element.addClass(newValue);
	    });

	    scope.$watch('loading',function(newValue,oldValue){
		if(newValue)
		    element.addClass('loading');
		else
		    element.removeClass('loading');
	    });

	    scope.$watch('session.userId',function(newValue,oldValue){
		if(newValue)
		    element.removeClass('login');
		else
		    element.addClass('login');
	    });

	}
    };
});

mod.directive('avVisited', [function() {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function(scope, element, attrs, ctrl) {
      ctrl.$visited = false;
      element.bind('blur', function(evt) {
        scope.$apply(function() {ctrl.$visited = true;});
      });
    }
  }
}]);

mod.directive('avScrollByDragging', function($interval) {
    return {
	restrict: 'A',
	link: function(scope,element,attrs) {
	    var options = {
		offset: 20,
		boundary: 50,
		top: 30,
		interval: 100
	    }

	    var scrollingUp = undefined;
	    var startScrollUp = function() {
		if(scrollingUp !=undefined)
		    return;
		scrollingUp = $interval(function() {
		    var newValue = element[0].scrollTop - options.offset;
		    if(newValue<0)
			newValue = 0;
		    element[0].scrollTop = newValue;
		},options.interval);
	    }
	    var stopScrollUp = function() {
		if(scrollingUp != undefined) {
		    $interval.cancel(scrollingUp);
		    scrollingUp = undefined;
		}
	    }

	    var scrollingDown = undefined;
	    var startScrollDown = function() {
		if(scrollingDown !=undefined)
		    return;
		scrollingDown = $interval(function() {
		    var max = element[0].scrollHeight - element[0].clientHeight;
		    var newValue = element[0].scrollTop + options.offset;
		    if(newValue>max)
			newValue = max;
		    element[0].scrollTop = newValue;
		},options.interval);
	    }
	    var stopScrollDown = function() {
		if(scrollingDown != undefined) {
		    $interval.cancel(scrollingDown);
		    scrollingDown = undefined;
		}
	    }

	    scope.dragMove = function(event) {
		if(event.lastY < options.top + options.boundary)
		    startScrollUp();
		else 
		    stopScrollUp();

		if(event.lastY > element[0].clientHeight + options.top - options.boundary)
		    startScrollDown();
		else
		    stopScrollDown();
		
	    }

	    scope.dragEnd = function() {
		stopScrollUp();
		stopScrollDown();
	    }

	}
    };
});
