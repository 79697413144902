var mod = angular.module('Solinox.services', ['ngResource','avalanche.config','avalanche.services']);

mod.factory('Documentos', function($resource,avalancheConfig) {
    return $resource(avalancheConfig.backendAddress+'/documentos/:docId?path=:path&fieldName=:fieldName&sourceId=:sourceId');
});

mod.factory('Licitacoes', function(Busca,avalancheConfig) {
    return {
	search: function(params,success,error) {
	    var newParams;
	    if(typeof(params)!='object')
		newParams = {};
	    else
		newParams = angular.copy(params);

	    newParams.path = avalancheConfig.path.processos;
	    if(!newParams.searchCriteria)
		newParams.searchCriteria = avalancheConfig.searchCriteria.processos;
	    return Busca.search(newParams,success,error);
	}
    }
});

mod.factory('Selecao', function(Busca,avalancheConfig,$rootScope) {
    return {
	search: function(params,success,error) {
	    var newParams;
	    if(typeof(params)!='object')
		newParams = {};
	    else
		newParams = angular.copy(params);

	    newParams.path = avalancheConfig.path.processos;
	    if(!newParams.searchCriteria)
		newParams.searchCriteria = avalancheConfig.searchCriteria.processos;
	    newParams.exclusionFilters_numOfOptions = 1;
	    newParams.exclusionFilters_1__fieldName = 'fase';
	    newParams.exclusionFilters_1__value = 'selecao';

	    if(newParams.usuario && newParams.usuario.userId!='todosUsuarios' ||
	       !newParams.usuario)
	    {
		newParams.exclusionFilters_numOfOptions++;
		newParams.exclusionFilters_2__fieldName = 'respCaptacao';
		if(newParams.usuario)
		    newParams.exclusionFilters_2__value = newParams.usuario.userId;
		else
		    newParams.exclusionFilters_2__value = $rootScope.session.userId;
	    }
	    return Busca.search(newParams,success,error);
	}
    }
});

mod.factory('Cadastro', function(Busca,avalancheConfig,$rootScope) {
    return {
	search: function(params,success,error) {
	    var newParams;
	    if(typeof(params)!='object')
		newParams = {};
	    else
		newParams = angular.copy(params);

	    newParams.path = avalancheConfig.path.processos;
	    newParams.orderBy = 'lastChanged';
	    newParams.order = '0';
	    if(!newParams.searchCriteria)
		newParams.searchCriteria = avalancheConfig.searchCriteria.processos;
	    newParams.exclusionFilters_numOfOptions = 2;
	    newParams.exclusionFilters_1__fieldName = 'fase';
	    newParams.exclusionFilters_1__value = 'captacao';
	    newParams.exclusionFilters_2__fieldName = 'fase';
	    newParams.exclusionFilters_2__value = 'descartado';

	    if(newParams.usuario && newParams.usuario.userId!='todosUsuarios' ||
	       !newParams.usuario)
	    {
		newParams.exclusionFilters_numOfOptions++;
		newParams.exclusionFilters_3__fieldName = 'respCaptacao';
		if(newParams.usuario)
		    newParams.exclusionFilters_3__value = newParams.usuario.userId;
		else
		    newParams.exclusionFilters_3__value = $rootScope.session.userId;
	    }
	    return Busca.search(newParams,success,error);
	}
    }
});

mod.factory('Pendentes', function(Busca,avalancheConfig) {
    return {
	search: function(params,success,error) {
	    var newParams;
	    if(typeof(params)!='object')
		newParams = {};
	    else
		newParams = angular.copy(params);

	    newParams.path = avalancheConfig.path.processos;
	    newParams.orderBy = 'dataSessao';
	    if(!newParams.searchCriteria)
		newParams.searchCriteria = avalancheConfig.searchCriteria.processos;
	    newParams.exclusionFilters_numOfOptions = 1;
	    newParams.exclusionFilters_1__fieldName = 'fase';
	    newParams.exclusionFilters_1__value = 'captacao';

	    return Busca.search(newParams,success,error);
	}
    }

});

mod.factory('MeusProcessos', function(Busca,avalancheConfig,$rootScope) {
    return {
	search: function(params,success,error) {
	    var newParams;
	    if(typeof(params)!='object')
		newParams = {};
	    else
		newParams = angular.copy(params);

	    newParams.path = avalancheConfig.path.processos;
	    newParams.orderBy = 'dataSessao';
	    if(!newParams.searchCriteria)
		newParams.searchCriteria = avalancheConfig.searchCriteria.processos;
	    newParams.exclusionFilters_numOfOptions = 1;
	    newParams.exclusionFilters_1__fieldName = 'fase';
	    newParams.exclusionFilters_1__value = 'cotacao';

	    if(newParams.usuario && newParams.usuario.userId!='todosUsuarios' ||
	       !newParams.usuario)
	    {
		newParams.exclusionFilters_numOfOptions++;
		newParams.exclusionFilters_2__fieldName = 'respCotacao';
		if(newParams.usuario)
		    newParams.exclusionFilters_2__value = newParams.usuario.userId;
		else
		    newParams.exclusionFilters_2__value = $rootScope.session.userId;
	    }
	    return Busca.search(newParams,success,error);
	}
    }
});

mod.factory('Cotacao', function($resource,avalancheConfig) {
    return $resource(avalancheConfig.backendAddress+'/cotacao/:docId');
});

mod.factory('Comissoes', function($resource,avalancheConfig) {
    return $resource(avalancheConfig.backendAddress+'/comissoes/:tipoEdital');
});

mod.factory('ICMS', function(Search,avalancheConfig) {
    return {
	search: function(params,success,error) {
	    var newParams;
	    if(typeof(params)!='object')
		newParams = {};
	    else
		newParams = angular.copy(params);
	    newParams.path = avalancheConfig.path.icms;

	    if(success==undefined)
		success = function(){};
	    if(error==undefined)
		error = function(){};
	    return Search.search(newParams,success,error);
	}
    }
});

mod.factory('Frete', function(Search,avalancheConfig) {
    return {
	search: function(params,success,error) {
	    var newParams;
	    if(typeof(params)!='object')
		newParams = {};
	    else
		newParams = angular.copy(params);
	    newParams.path = avalancheConfig.path.frete;

	    if(success==undefined)
		success = function(){};
	    if(error==undefined)
		error = function(){};
	    return Search.search(newParams,success,error);
	}
    }
});

mod.factory('Federais', function(Search,avalancheConfig) {
    return {
	search: function(params,success,error) {
	    var newParams;
	    if(typeof(params)!='object')
		newParams = {};
	    else
		newParams = angular.copy(params);
	    newParams.path = avalancheConfig.path.federais;

	    if(success==undefined)
		success = function(){};
	    if(error==undefined)
		error = function(){};
	    return Search.search(newParams,success,error);
	}
    }
});

mod.factory('Fornecedores', function(Search,avalancheConfig) {
    return {
	search: function(params,success,error) {
	    var newParams;
	    if(typeof(params)!='object')
		newParams = {};
	    else
		newParams = angular.copy(params);
	    newParams.path = avalancheConfig.path.fornecedores;
	    if(!newParams.searchCriteria)
		newParams.searchCriteria = avalancheConfig.searchCriteria.fornecedores;

	    if(success==undefined)
		success = function(){};
	    if(error==undefined)
		error = function(){};

	    return Search.search(newParams,success,error);
	}
    }
});

mod.factory('Busca', function($resource,avalancheConfig) {
    return $resource(avalancheConfig.backendAddress+'/busca',null,
		     { search:  {method:'POST'}});
});

mod.factory('Agenda', function(Busca,avalancheConfig,$rootScope) {
    return {
	search: function(params,success,error) {
	    var newParams;
	    if(typeof(params)!='object')
		newParams = {};
	    else
		newParams = angular.copy(params);

	    newParams.path = avalancheConfig.path.processos;
	    if(!newParams.searchCriteria)
		newParams.searchCriteria = avalancheConfig.searchCriteria.processos;
	    newParams.exclusionFilters_numOfOptions = 4;
	    newParams.exclusionFilters_1__fieldName = 'fase';
	    newParams.exclusionFilters_1__value = 'captacao';
	    newParams.exclusionFilters_2__fieldName = 'fase';
	    newParams.exclusionFilters_2__value = 'cotacao';
	    newParams.exclusionFilters_3__fieldName = 'fase';
	    newParams.exclusionFilters_3__value = 'disputa';
	    newParams.exclusionFilters_4__fieldName = 'acompanhamento';
	    newParams.exclusionFilters_4__value = '0';

	    if(newParams.usuario && newParams.usuario.userId!='todosUsuarios' ||
	       !newParams.usuario)
	    {
		newParams.exclusionFilters_numOfOptions++;
		newParams.exclusionFilters_5__fieldName = 'respDisputa';
		if(newParams.usuario)
		    newParams.exclusionFilters_5__value = newParams.usuario.userId;
		else
		    newParams.exclusionFilters_5__value = $rootScope.session.userId;
	    }
	    newParams.ordemUrgencia = '1';
	    return Busca.search(newParams,success,error);
	}
    }
});

mod.factory('Acompanhamento', function(Busca,avalancheConfig,$rootScope) {
    return {
	search: function(params,success,error) {
	    var newParams;
	    if(typeof(params)!='object')
		newParams = {};
	    else
		newParams = angular.copy(params);

	    newParams.path = avalancheConfig.path.processos;
	    if(!newParams.searchCriteria)
		newParams.searchCriteria = avalancheConfig.searchCriteria.processos;
	    newParams.exclusionFilters_numOfOptions = 4;
	    newParams.exclusionFilters_1__fieldName = 'fase';
	    newParams.exclusionFilters_1__value = 'captacao';
	    newParams.exclusionFilters_2__fieldName = 'fase';
	    newParams.exclusionFilters_2__value = 'cotacao';
	    newParams.exclusionFilters_3__fieldName = 'fase';
	    newParams.exclusionFilters_3__value = 'disputa';
	    newParams.exclusionFilters_4__fieldName = 'acompanhamento';
	    newParams.exclusionFilters_4__value = '1';

	    if(newParams.usuario && newParams.usuario.userId!='todosUsuarios' ||
	       !newParams.usuario)
	    {
		newParams.exclusionFilters_numOfOptions++;
		newParams.exclusionFilters_5__fieldName = 'respDisputa';
		if(newParams.usuario)
		    newParams.exclusionFilters_5__value = newParams.usuario.userId;
		else
		    newParams.exclusionFilters_5__value = $rootScope.session.userId;
	    }
	    newParams.ordemUrgencia = '1';
	    return Busca.search(newParams,success,error);
	}
    }
});

mod.factory('Disputa', function($resource,avalancheConfig) {
    return $resource(avalancheConfig.backendAddress+'/disputa/:docId');
});

// POST (cria link de documento para disputa)
mod.factory('DocumentosDisputa', function($resource,avalancheConfig) {
    return $resource(avalancheConfig.backendAddress+'/documentos-disputa/');
});

// somente POST para criação de nova proposta, associada como DisputaDocumentos
mod.factory('Proposta', function($resource,avalancheConfig) {
    return $resource(avalancheConfig.backendAddress+'/proposta/');
});

mod.factory('BuscaArquivosProcessos', function(Search,avalancheConfig) {
    return {
	search: function(params,success,error) {
	    var newParams;
	    if(typeof(params)!='object')
		newParams = {};
	    else
		newParams = angular.copy(params);
	    newParams.path = avalancheConfig.path.documentos;
	    if(!newParams.searchCriteria)
		newParams.searchCriteria = avalancheConfig.searchCriteria.buscaArquivosProcesso;

	    if(success==undefined)
		success = function(){};
	    if(error==undefined)
		error = function(){};

	    return Search.search(newParams,success,error);
	}
    }
});

mod.factory('DocumentoComumParser', function($filter) {
    return function(doc) {
	if(!doc.dataEmissao || !doc.validade)
	    return doc;
	var now = new Date(),
	tzo = -now.getTimezoneOffset(),
	dif = tzo >= 0 ? '+' : '-',
	pad = function(num) {
	    var norm = Math.abs(Math.floor(num));
	    return (norm < 10 ? '0' : '') + norm;
	};
	doc.dataEmissao = new Date($filter('mysqlDatetimeToISO')(doc.dataEmissao)+
				    dif + pad(tzo / 60) + ':' + pad(tzo % 60));
	doc.vencimento = new Date(doc.dataEmissao);
	doc.vencimento.setMonth(doc.vencimento.getMonth()+parseInt(doc.validade));
	doc.pendencia = doc.vencimento<now;
	return doc;
    };
});

mod.factory('DocumentosComuns', function(Search,DocumentoComumParser,avalancheConfig) {
    return {
	search: function(params,success,error) {
	    var newParams;
	    if(typeof(params)!='object')
		newParams = {};
	    else
		newParams = angular.copy(params);
	    newParams.path = avalancheConfig.path.docsComuns;
	    if(!newParams.searchCriteria)
		newParams.searchCriteria = avalancheConfig.searchCriteria.docsComuns;
	    newParams.orderBy = 'lastChanged';
	    newParams.order = '0';

	    if(success==undefined)
		success = function(){};
	    if(error==undefined)
		error = function(){};

	    var data = Search.search(newParams,function() {
		for(var i=0; i<data.children.length; i++)
		    data.children[i] = DocumentoComumParser(data.children[i]);
		success();
	    },error);
	    return data;
	}
    }
});

mod.factory('UsuariosResponsaveis', function($resource,avalancheConfig) {
    return $resource(avalancheConfig.backendAddress+'/usuarios-responsaveis/:state');
});

mod.config(function($provide) {
    $provide.decorator('HistoryFormatter', function ($delegate) {
	var obj = {};
	obj.format = function (field,value,translations)  {
	    switch(field.attrs.type)
	    {
	    case 'calcPercent':
		return obj.formatPercent(value);

	    case 'calcCurrency':
		return obj.formatCurrency(value);

	    case 'calcCurrencyQtd':
		return value;

	    default:
		return $delegate.format(field,value,translations);
	    }
	}
	return obj;
    });
});

mod.factory('ReservaCotacao', function($resource,avalancheConfig) {
    return $resource(avalancheConfig.backendAddress+'/reserva-cotacao/:docId');
});
