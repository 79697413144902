var mod = angular.module('Solinox.controllers.Main',[])

mod.controller('MainCtrl', function($scope, $rootScope, $controller, $state, $urlRouter,$window,$filter,Config,States,Session,RuntimeStates,Logout,avalancheConfig){
    $controller('LoadingCtrl', {$scope: $scope});
    $controller('LibraryCtrl', {$scope: $scope});

    $scope.config = Config.search();

    $scope.$state = $state;
    $scope.dateFormat = avalancheConfig.dateFormat;
    $scope.datetimeFormat = avalancheConfig.datetimeFormat;

    $scope.logout = function(){
	$scope.beginTasks();
	var load = $scope.pushTask();
	$scope.watchTasks();
	Logout.save(function(){
	    $window.location = '/';
	    load.resolve();
	},function(){
	    load.reject();
	});
    }

    $scope.loginSetup = function() {
	// rota padrão
	RuntimeStates.otherwise($rootScope.session.paginaInicial);
	$urlRouter.sync();
	$urlRouter.listen();
	
	// permissões do usuário
	var menuItensPerms = $scope.permission([
	    'licitacoes.captacao','licitacoes.cotacao',
	    'licitacoes.disputa','licitacoes.logistica'
	]);
	$scope.captacaoPerm = menuItensPerms[0];
	$scope.cotacaoPerm = menuItensPerms[1];
	$scope.disputaPerm = menuItensPerms[2];
	$scope.logisticaPerm = menuItensPerms[3];
    }


    // carrega estados
    $scope.beginTasks();
    var load = $scope.pushTask();
    $scope.watchTasks();
    var data = States.search(function() {
	$scope.states = data.children;
	for(var i=0; i<$scope.states.length; i++) {
	    RuntimeStates.addState($scope.states[i].name, $scope.states[i]);
	    switch($scope.states[i].name)
	    {
	    case 'licitacoes.cotacao.pendentes':
		$scope.cotacaoPendentes = $scope.states[i];
		break;
	    case 'licitacoes.disputa.agenda':
		$scope.disputaAgenda = $scope.states[i];
		break;
	    }
	}

	$rootScope.session = {};
	$rootScope.$watch('session',function(newValue,oldValue){
	    if(!oldValue.userId && newValue.userId)
		$scope.loginSetup();
	});

	$rootScope.session = Session.get(function(){
	    if($rootScope.session.userId)
		$scope.loginSetup();
	});

	load.resolve();
    }, function() {
	load.reject();
    });

    $scope.permission = function(state) {
	if(typeof(state)!='object')
	    state = [state];

	var ret = [];
	for(var k=0; k<state.length; k++)
	{
	    var notFound = true;
	    for(var i=0; i<$rootScope.session.groups.length && notFound; i++)
		for (var j=0; $rootScope.session.groups[i].permissions &&
		     j<$rootScope.session.groups[i].permissions.length && notFound; j++)
		    if($rootScope.session.groups[i].permissions[j].indexOf(state[k])==0)
			notFound = false;
	    ret.push(!notFound);
	}

	if(ret.length==1)
	    return ret[0];
	return ret;
    }
    
    $scope.openFornecedor = function(params) {
	params.path = avalancheConfig.path.fornecedores;
	params.title = 'Fornecedor';
	params.excludeFields = ['userId','groupId','userRight','groupRight',
				'otherRight','creationDate','lastChanged',
				'lastChangedUserId','keywords'];
	$scope.openDocModal(params);
    }

   
    $scope.openArquivo = function(params) {
	params.title = 'Arquivo';
	params.buttons = { remove: false, history: true };
	params.excludeFields = ['userId','groupId','userRight','groupRight',
				'otherRight','creationDate','lastChanged',
				'lastChangedUserId','keywords'];
	$scope.openDocModal(params);
    }

});


mod.controller('BuscaCtrl',function ($rootScope,$scope,$q,$filter,$state,UsuariosResponsaveis,avalancheConfig) {
    $scope.modelDateFormat = "yyyy-MM-dd";

    // variáveis rootScope da busca
    $rootScope.busca = {
	limit: avalancheConfig.busca.limit,
	offset: 0
    };   

    $rootScope.setBusca = function(params) {
	if(!params)
	    params = { state: $state.current };
	else
	{
	    if(!params.state)
		params.state = $state.current;
	}

	// limpa busca
	$rootScope.docList = [];
	if($scope.limparBusca)
	{
	    $scope.busca.filter = undefined;
	    $scope.busca.date = undefined;
	    $scope.busca.offset = 0;
	    if(params.state!=$scope.busca.state)
		$scope.busca.usuario = undefined;
	}
	else
	    $scope.limparBusca = true;


	$rootScope.loadBusca = params.loadBusca;

	$scope.busca.state = params.state;
	$scope.onStateChange(params.state,function(){
	    if(typeof($rootScope.loadBusca)=='function')
		$rootScope.loadBusca();
	});
    }

    $scope.limparBusca = true;

    var paginationListnerDesreg = $rootScope.$on('av.pagination.offset', function(event,offset){
	$scope.busca.offset=offset;
	$rootScope.docList = undefined;
	$rootScope.loadBusca();
    });

    $scope.$on('$destroy', function() {
	paginationListnerDesreg();
    });

    $scope.buscar = function() {
	$scope.limparBusca = false;
	$scope.busca.offset = 0;
	$state.go($scope.busca.state.name,undefined,{reload: true});
    }

    var meusProcessos = {
	name: "Meus processos",
	userId: $scope.session.userId,
	meusProcessos: true
    };

    var todosUsuarios = {
	name: "Todos usuários",
	userId: "todosUsuarios"
    };

    $scope.filtraEstados = function(item) {
	if(!item.area)
	    return false;

	return $scope.permission(item.name);
    }

    function getParentStateName(name)
    {
	var split = name.split('.');
	split.pop(); // removes last level;
	return split.join('.');
    }

    $scope.onStateChange = function(toState, success) {
	// somente usuários com permissão para administração do estado podem
	// filtrar por usuário
	$scope.showUsuarios = 
	    $scope.permission(toState.name+'.admin') ||
	    $scope.permission(getParentStateName(toState.name)+'.admin');

	if($scope.showUsuarios)
	{    
	    var load = $scope.pushTask();
	    var usuariosBusca = UsuariosResponsaveis.query({state:toState.name},function() {

		usuariosBusca.unshift(todosUsuarios);

		// somente usuários responsáveis tem opção para "meus processos"
		if($scope.permission(toState.name+'.responsavel') ||
		   $scope.permission(getParentStateName(toState.name)+'.responsavel'))
		    usuariosBusca.unshift(meusProcessos);

		$scope.usuariosBusca = usuariosBusca;

		if($scope.busca.usuario==undefined)
		    $scope.busca.usuario = usuariosBusca[0];
		else
		{
		    var notFound = true;
		    for(var i=0; i<usuariosBusca.length && notFound; i++)
		    {
			if(usuariosBusca[i].userId==$scope.busca.usuario.userId)
			{
			    $scope.busca.usuario = usuariosBusca[i];
			    notFound = false;
			}
		    }
		    if(notFound)
			$scope.busca.usuario = usuariosBusca[0];
		}
		if(typeof(success)=='function')
		    success();
		load.resolve();
	    }, function() {
		if(typeof(success)=='function')
		    success();
	    	load.reject();
	    });
	}
	else
	{
	    if(typeof(success)=='function')
		success();
	    $scope.busca.usuario = meusProcessos;
	}
    }

    function parseDate(input) {
	var parts = input.match(/(\d+)/g);
	// new Date(year, month [, date [, hours[, minutes[, seconds[, ms]]]]])
	return new Date(parts[0], parts[1]-1, parts[2]); // months are 0-based
    }

    $scope.incrDate = function(days) {
	var newDate;
	if(!angular.isDefined($scope.busca.date))
	    newDate = new Date();
	else 
	{
	    newDate = parseDate($scope.busca.date);
	    newDate.setDate(newDate.getDate()+days);
	}
	$scope.busca.date = $filter('date')(newDate, $scope.modelDateFormat);
    }	
});

mod.controller('LicitacoesCtrl',function($scope,$rootScope,$state,$alert,Documents,ICMS,Frete,Federais,Comissoes,avalancheConfig){
    $scope.beginTasks();
    $scope.processExpanded = -1;
    $rootScope.fases = avalancheConfig.fases;

    $scope.shared = {selected: {} };


    $scope.saveDoc = function(docId,params,success,error) {
	var savingAlert = $alert({
	    animation: 'am-slide-top',
	    placement: 'top-right',
	    title: $scope.translations['savingTag']+'...',
	    type: 'info',
	    show: true,
	    container: 'body',
	    dismissable: true
	});
	Documents.save({
	    docId: docId,
	},params, function(doc) {
	    savingAlert.hide();
	    if(typeof(success)=='function')
		success(doc);
	},function(resp) {
	    savingAlert.hide();
	    var errorAlert = $alert({
		animation: 'am-slide-top',
		placement: 'top-right',
		title: 'ERRO: ',
		content: resp.data.statusMessage,
		type: 'warning',
		show: true,
		container: 'body',
		duration: 5,
		dismissable: true
	    });	  
	    if(typeof(error)=='function')
		error(resp);
	});
    }

    $scope.unselect = function() {
	$scope.shared.selected = {};

	switch($state.current.name)
	{
	case 'licitacoes.cotacao.processo':
	    $state.go('licitacoes.cotacao.meusprocessos');
	    break;
	case 'licitacoes.disputa.processo':
	    $state.go('licitacoes.disputa.agenda');
	    break;
	case 'licitacoes.logistica.processo':
	    $state.go('licitacoes.logistica.aguardandoata');
	    break;
	}
    }

    $rootScope.onFaseClick = function($event,fase) {
	var faseId = eval('$rootScope.fases.'+fase+'.value');
	if($rootScope.activeFase && $rootScope.activeFase.value == faseId)
	{
	    $event.preventDefault();
	    return;
	}

	if(!eval('$scope.'+fase+'Perm'))
	    $event.preventDefault();	
    }

    $rootScope.showFaseCheck = function(fase) {
	return $rootScope.activeFase && $scope.shared.selected.fase
	    && $rootScope.activeFase.value > $rootScope.fases.captacao.value
	    && eval('$rootScope.fases.'+$scope.shared.selected.fase+'.value') >= fase.value;
    }

    $scope.openCadastro = function(params) {
	params.path = avalancheConfig.path.processos;
	params.title = 'Cadastro';
	params.excludeFields = ['userId','groupId','userRight','groupRight',
				'otherRight','creationDate','lastChanged',
				'lastChangedUserId','keywords','fase',
				'nroConlicitacao','nroBec','respCaptacao',
				'respCotacao','respDisputa','numItens',
				'dataSessao','acompanhamento','eletronico',
				'itensCadastrados','situacao'];
	$scope.openDocModal(params);
    }

    $scope.openCadastroSelected = function() {
	var params = {
	    docId: $scope.shared.selected.docId,
	    onSave: function(doc) {
		$state.go($state.current, undefined, {reload: true});
	    },
	    onDelete: function() {
		$scope.unselect();
	    }
	}
	$scope.openCadastro(params);
    }

    var permCaptaResp = $scope.permission('licitacoes.captacao.responsavel');
    $scope.openCadastroBusca = function($index) {
	var params = { 
	    docId: $index!=undefined?$rootScope.docList.children[$index].docId:undefined, 
	    onSave: function(doc) {
		if($index!=undefined)
		    $rootScope.docList.children[$index] = doc.attrs;
		else
		{
		    if($scope.busca.offset>0 ||
		       $scope.busca.filter != undefined 
		       && $scope.busca.filter.trim() != '' ||
		       $scope.busca.date != undefined ||
		       $scope.busca.usuario != undefined && 
		       $scope.busca.usuario.userId != 'todosUsuarios' &&
		       !$scope.busca.usuario.meusProcessos)
		    {
			$scope.busca.filter = undefined;
			$scope.busca.date = undefined;
			$scope.busca.offset = 0;
			$scope.busca.usuario = undefined;
			$rootScope.docList = undefined;
			$rootScope.loadBusca();
		    }
		    else
		    {
			$rootScope.docList.children.unshift(doc.attrs);
			if($rootScope.docList.totalResults>=$scope.busca.limit)
			    $rootScope.docList.children.pop();
		    }
		}
	    },
	    onBeforeDelete: function () {
		return $rootScope.docList.children.splice($index,1);
	    },
	    onUndoDelete: function (undoData) {
		$rootScope.docList.children.splice($index,0,undoData[0]);
	    },
	    onDelete: function() {
		if($rootScope.docList.totalResults>$scope.busca.offset+$scope.busca.limit)
		    $rootScope.loadBusca();
	    }
	}
	if($index==undefined)
	{
	    params.extraFields =  {
		fase: 'captacao'
	    };
	    if(permCaptaResp)
		params.extraFields.respCaptacao = $rootScope.session.userId;
	}
	    
	$scope.openCadastro(params);
    }

    $scope.$on('tooltip.show',  function(event, elem){
	var input = elem.$element.find('input');
	if(input.length>0)
	    input[0].select();
    });

    var onOpenCalculadora = function(scope){
	// novo campo contendo descrição do item
	scope.doc.children.unshift({
	    attrs: {
		value: scope.docModalParams.descr,
		type: 'itemFixedTitle'
	    }
	});

	// para nova cotação, ajusta valores padrão 
	if(!scope.doc.attrs.docId)
	{
	    scope.doc.attrs.desconto = '0';
	    scope.doc.attrs.creditoICMS = '0';
	    scope.doc.attrs.custoFrete = '0';
	    scope.doc.attrs.debitoICMS = '0';
	    scope.doc.attrs.impostosFederais = '0';
	    var getComissoes = Comissoes.get({
		tipoEdital: $scope.shared.selected.tipoEdital
	    },function(){
		scope.doc.attrs.comissoes = String(getComissoes.comissoes*100);
	    });
	    scope.doc.attrs.lucratividade = 
		$scope.config.children[0].lucratividade*100;
	    scope.doc.attrs.licitacaoId = $scope.shared.selected.docId;
	    scope.doc.attrs.itemId = scope.docModalParams.itemId;
	}

	var fields = scope.doc.fields;
	
	fields.precoMercadoria.signal = "+";
	fields.desconto.signal = "-";
	fields.creditoICMS.signal = "-";
	fields.creditoICMS.opcoes = ICMS.search();
	fields.custoCompra.signal = "=";
	fields.custoCompra.disabled = true;
	fields.custoFrete.signal = "+";
	fields.custoFrete.opcoes = Frete.search();
	fields.debitoICMS.signal = "+";
	fields.debitoICMS.opcoes = fields.creditoICMS.opcoes;
	fields.impostosFederais.signal = "+";
	fields.impostosFederais.opcoes = Federais.search();
	fields.comissoes.signal = "+";
	fields.comissoes.disabled = true;
	fields.lucratividade.signal = "+";
	fields.precoVenda.signal = "=";
	fields.precoVenda.total = true;
	fields.precoVenda.disabled = true;
	fields.totalVenda.quantidade = scope.docModalParams.quantidade;
	fields.markUp.disabled = true;
	
	scope.$watch('doc.attrs.desconto/100*doc.attrs.precoMercadoria',
		      function(newValue,oldValue){
			  fields.desconto.total = newValue;
		      });
	scope.$watch('doc.attrs.creditoICMS/100*(doc.attrs.precoMercadoria-doc.fields.desconto.total)',
		      function(newValue,oldValue){
			  fields.creditoICMS.total = newValue;
		      });
	
	scope.$watch('doc.attrs.precoMercadoria-doc.fields.desconto.total-doc.fields.creditoICMS.total',
		      function(newValue,oldValue){
			  scope.doc.attrs.custoCompra = newValue;
		      });
	scope.$watch('doc.attrs.custoCompra*100/(100-doc.attrs.custoFrete-doc.attrs.debitoICMS-doc.attrs.impostosFederais-doc.attrs.comissoes-doc.attrs.lucratividade)',
		      function(newValue,oldValue){
			  scope.doc.attrs.precoVenda = newValue;
		      });
	scope.$watch('doc.attrs.custoFrete/100*doc.attrs.precoVenda',
		      function(newValue,oldValue){
			  fields.custoFrete.total = newValue;
		      });
	scope.$watch('doc.attrs.debitoICMS/100*doc.attrs.precoVenda',
		      function(newValue,oldValue){
			  fields.debitoICMS.total = newValue;
		      });
	scope.$watch('doc.attrs.impostosFederais/100*doc.attrs.precoVenda',
		      function(newValue,oldValue){
			  fields.impostosFederais.total = newValue;
		      });
	scope.$watch('doc.attrs.comissoes/100*doc.attrs.precoVenda',
		      function(newValue,oldValue){
			  fields.comissoes.total = newValue;
		      });
	scope.$watch('doc.attrs.lucratividade/100*doc.attrs.precoVenda',
		      function(newValue,oldValue){
			  fields.lucratividade.total = newValue;
		      });
	scope.$watch('doc.fields.totalVenda.quantidade*doc.attrs.precoVenda',
		      function(newValue,oldValue){
			  scope.doc.attrs.totalVenda = newValue;
		      });
	scope.$watch('doc.attrs.precoVenda-doc.attrs.custoCompra',
		      function(newValue,oldValue){
			  scope.doc.attrs.markUp = newValue;
		      });
    }

    $scope.openCalculadora = function(item) {
	$scope.openDocModal({ 
	    docId: item.cotacaoId, 
	    path: avalancheConfig.path.cotacoes,
	    title: 'Calculadora',
	    quantidade: item.quantidade,
	    excludeFields: ['userId','groupId','userRight','groupRight',
			    'otherRight','creationDate','lastChanged',
			    'lastChangedUserId','keywords','itemId',
			    'cadastrado'],
	    descr: item.descr,
	    itemId: item.itemId,
	    onOpen: onOpenCalculadora,
	    onSave: function (doc) {
		doc.attrs.cotacaoId = doc.attrs.docId;
		delete doc.attrs.docId;
		angular.extend(item,doc.attrs);
	    },
	    buttons: { remove: false, history: true }
	});
    }


});

mod.controller('LicitacoesBuscaCtrl',function($rootScope, $scope, Licitacoes) {
    $rootScope.activeFase = undefined;

    $scope.setBusca({ 
	loadBusca: function() {
	    $scope.beginTasks();
	    var load = $scope.pushTask();
            $scope.watchTasks();
	    var data = Licitacoes.search($scope.busca,function() {
		$rootScope.docList = data;
		load.resolve();
	    }, function() {
		load.reject();
	    });
	}
    });		   
});


mod.controller('CaptacaoCtrl',function($rootScope,$scope,$state,UsuariosResponsaveis){
    $scope.beginTasks();

    $rootScope.activeFase = $rootScope.fases.captacao;

    $scope.tabs = [];

    var tabsPerms = $scope.permission(['licitacoes.captacao.selecao',
				       'licitacoes.captacao.cadastro']);

    if(tabsPerms[0])
	$scope.tabs.push({ 
	    title:'Seleção de licitações', 
	    name: "selecao", 
	    template: 'selecao.html'
	});
    if(tabsPerms[1])
	$scope.tabs.push({
	    title:'Cadastro', 
	    name: "cadastro", 
	    template: 'cadastro.html'
	});

    $scope.$watch('tabs.activeTab',function(newValue,oldValue){
	if(oldValue != newValue)
	    $state.go('licitacoes.captacao.'+newValue);
    });

    // administradores da captacao podem alterar responsáveis
    if($scope.permission('licitacoes.captacao.admin'))
	$scope.responsaveis = UsuariosResponsaveis.query({state:'licitacoes.captacao.selecao'});

    $scope.setRespCaptacao = function(lic) {
	$scope.saveDoc(lic.docId, {respCaptacao: lic.respCaptacao});
    }

    if($state.current.name=='licitacoes.captacao')
	$state.go('licitacoes.captacao.selecao');	
});

mod.controller('SelecaoCtrl',function($rootScope,$scope,Selecao,$alert){
    $scope.tabs.activeTab = 'selecao';

    $scope.setBusca({
	loadBusca: function() {
	    $scope.beginTasks();
	    var load = $scope.pushTask();
	    $scope.watchTasks();
	    var data = Selecao.search($scope.busca,function() {
		$rootScope.docList = data;
		load.resolve();
	    }, function() {
		load.reject();
	    });
	}
    });

    $scope.setFase = function(fase,lic,$index) {
	if(fase=='captacao' && lic.numItens==0)
	{
	    var error = $alert({
		animation: 'am-slide-top',
		placement: 'top-right',
		title: 'ERRO: ',
		content: 'Licitação precisa de itens cadastrados para ser selecionada.',
		type: 'warning',
		show: true,
		container: 'body',
		duration: 5,
		dismissable: true
	    });	    
	    return;
	}

	$rootScope.docList.children.splice($index,1);
	$scope.saveDoc(lic.docId,{fase: fase}, function() {
	    // recarrega selecao se houverem mais resultados
	    if($rootScope.docList.totalResults>$scope.busca.offset+$scope.busca.limit)
		$rootScope.loadBusca();
	},function(resp) {
	    $rootScope.loadBusca();
	});
    }
});

mod.controller('CadastroCtrl',function($rootScope,$scope,Cadastro){
    $scope.tabs.activeTab = 'cadastro';

    $scope.setBusca({
	loadBusca: function() {
	    $scope.beginTasks();
	    var load = $scope.pushTask();
	    $scope.watchTasks();
	    var data = Cadastro.search($scope.busca,function() {
		$rootScope.docList = data;
		load.resolve();
	    }, function() {
		load.reject();
	    });
	}
    });

    $scope.permissionSelecao = $scope.permission('licitacoes.captacao.selecao');



    $scope.setFase = function(fase,lic,$index) {
	var faseAnt = undefined;
	if(fase=="selecao")
	    $rootScope.docList.children.splice($index,1);
	else
	{
	    faseAnt = lic.fase;
	    lic.fase = fase;
	}
	$scope.saveDoc(lic.docId,{fase: fase}, function() {
	    if(fase=="selecao" && 
	       $rootScope.docList.totalResults>$scope.busca.offset+$scope.busca.limit)
		$rootScope.loadBusca();
	}, function(resp) {
	    if(fase=="selecao")
		$rootScope.loadBusca();
	    else
		lic.fase = faseAnt;
	});
    }

});

mod.controller('CotacaoCtrl',function($rootScope,$scope,$state,$alert,$interval,avalancheConfig,Documents){
    $scope.beginTasks();
    $rootScope.activeFase = $rootScope.fases.cotacao;
    if($state.current.name=='licitacoes.cotacao')
    {
	if($scope.shared.selected.docId)
	    $state.go('licitacoes.cotacao.processo',
		      {docId:$scope.shared.selected.docId});
	else
	    $state.go('licitacoes.cotacao.meusprocessos');
    }

    $scope.tabs = [];

    var tabsPerms = $scope.permission(['licitacoes.cotacao.pendentes',
				       'licitacoes.cotacao.meusprocessos']);

    if(tabsPerms[1])
	$scope.tabs.push({
	    title:'Meus processos', 
	    name: "meusprocessos", 
	    template: 'meusProcessos.html'
	});
    if(tabsPerms[0])
	$scope.tabs.push({ 
	    title:'Pendentes', 
	    name: "pendentes", 
	    template: 'pendentes.html'
	});

    $scope.$watch('tabs.activeTab',function(newValue,oldValue){
	if(oldValue != newValue && newValue != undefined)
	    $state.go('licitacoes.cotacao.'+newValue);
    });


    $scope.finalizarCotacao = function(lic) {

	var finalizandoAlert = $alert({
	    animation: 'am-slide-top',
	    placement: 'top-right',
	    title: 'Finalizando cotação.',
	    template: 'finalizando.html',
	    type: 'info',
	    show: true,
	    container: 'body',
	    dismissable: true,
	    scope: $scope
	});

	foundItem = false;
	for(var i=0; i<lic.itens.length && !foundItem; i++) {
	    for(var j=0; j<lic.itens[i].itens.length && !foundItem; j++) {
		if(lic.itens[i].itens[j].descartado=='0') {
		    foundItem = true;
		}
	    }
	}
    
		    
	var cancelInterval = $interval(function() {
	    finalizandoAlert.hide();
	    Documents.save({
		docId: lic.docId
	    },{
		fase: foundItem?'disputa':'arquivo'
	    }, function(doc){
		$rootScope.loadBusca();
	    }, function(resp) {
		var error = $alert({
		    animation: 'am-slide-top',
		    placement: 'top-right',
		    title: $scope.translations['alertErrorTag']+':',
		    content: resp.data.statusMessage,
		    type: 'danger',
		    show: true,
		    container: 'body',
		    duration: 5,
		    dismissable: true
		});
	    });
	},avalancheConfig.finalizarTimeout,1);

	$scope.cancelarFinaliza = function($event) {
	    $event.preventDefault();
	    $interval.cancel(cancelInterval);
	    finalizandoAlert.hide();	    
	    $state.go('licitacoes.cotacao.processo',{docId:lic.docId});
	}
    }

});

mod.controller('PendentesCtrl',function($rootScope,$scope,Pendentes){
    $scope.tabs.activeTab = 'pendentes';

    $scope.setBusca({
	loadBusca: function() {
	    $scope.beginTasks();
	    var load = $scope.pushTask();
	    $scope.watchTasks();
	    var data = Pendentes.search($scope.busca,function() {
		$rootScope.docList = data;
		load.resolve();
	    }, function() {
		load.reject();
	    });
	}
    });
});

mod.controller('MeusProcessosCtrl',function($rootScope,$scope,MeusProcessos,UsuariosResponsaveis){
    $scope.tabs.activeTab = 'meusprocessos';
    $scope.setBusca({
	loadBusca: function() {
	    $scope.beginTasks();
	    var load = $scope.pushTask();
	    $scope.watchTasks();
	    var data = MeusProcessos.search($scope.busca,function() {
		$rootScope.docList = data;
		load.resolve();
	    }, function() {
		load.reject();
	    });
	}
    });

    // administradores da cotação podem alterar responsáveis
    if($scope.permission('licitacoes.cotacao.meusprocessos.admin'))
	$scope.responsaveis = UsuariosResponsaveis.query({state:'licitacoes.cotacao.meusprocessos'});

    $scope.setRespCotacao = function(lic) {
	$scope.saveDoc(lic.docId,{respCotacao: lic.respCotacao});
    }
});

mod.controller('CotacaoProcessoCtrl',function($scope, $rootScope,$stateParams,$alert,$interval,avalancheConfig,Cotacao,Fornecedores,Mailto,Documents,ReservaCotacao,UsuariosResponsaveis){
    $scope.beginTasks();

    $scope.shared.pathComentarios = '/comentarios-cotacao/';

    $scope.setBusca({ state: $scope.cotacaoPendentes});

    $scope.anyItemSelected = false;

    $scope.shared.selected = {};
    var load = $scope.pushTask();
    $scope.watchTasks();
    var data = Cotacao.get({docId:$stateParams['docId']},function() {
	$scope.shared.selected = data;

	if(!$scope.shared.selected.respCotacao.attrs.id && 
	   $scope.permission('licitacoes.cotacao.meusprocessos.responsavel'))
	{
	    var saving = $alert({
		animation: 'am-slide-top',
		placement: 'top-right',
		title: 'AVISO: ',
		content: 'Reservando o processo para você',
		type: 'info',
		show: true,
		container: 'body',
		dismissable: true
	    });

	    var intervalFinished = false;
	    var saved = false;

	    var minInterval = $interval(function() {
		if(saved)
		    saving.hide();
		intervalFinished = true;
	    },5000,1);

	    // caso usuário seja responsável por captação, 
	    // reserva processo para ele
	    ReservaCotacao.save({
		docId: $scope.shared.selected.docId
	    },function(data){
		var respCotacao = $scope.shared.selected.respCotacao;
		respCotacao.attrs.id = data.respCotacao;
		for(var i=0; i<respCotacao.children.length; i++) {
		    if(respCotacao.attrs.id == respCotacao.children[i].id)
			respCotacao.attrs.value = respCotacao.children[i].value;
		}
		$scope.shared.selected.fase = data.fase;
		if(intervalFinished)
		    saving.hide();
		saved = true;
	    }, function(resp){
		saving.hide();
		var error = $alert({
		    animation: 'am-slide-top',
		    placement: 'top-right',
		    title: 'ERRO: ',
		    content: resp.data.statusMessage,
		    type: 'warning',
		    show: true,
		    container: 'body',
		    duration: 5,
		    dismissable: true
		});	    		
	    });

	}

	// monitora lotes e itens para mapear lotes selecionados
	var allExpr = "";
	var allDone = "";
	for(var i=0; i<$scope.shared.selected.itens.length; i++)
	{
	    var loteExpr = "";
	    var totalLoteExpr = "";
	    for(var j=0; j <$scope.shared.selected.itens[i].itens.length; j++)
	    {
		var item = $scope.shared.selected.itens[i].itens[j];
		if(!item.cotacaoId)
		{
		    item.precoMercadoria = "0";
		    item.desconto = '0';
		    item.creditoICMS = '0';
		    item.custoFrete = '0';
		    item.debitoICMS = '0';
		    item.impostosFederais = '0';
		    item.comissoes = '0';
		    item.lucratividade = '0';
		}

		var itemExpr = 'shared.selected.itens['+i+'].itens['+j+'].';
		if(j>0)
		    loteExpr += ' && ';
		loteExpr += itemExpr+'checked';

		if(allExpr!="")
		    allExpr += ' || ';
		allExpr += itemExpr+'checked';

		var descontoTotal = itemExpr+"desconto/100*"+itemExpr+"precoMercadoria";
		var creditoICMSTotal = itemExpr+"creditoICMS/100*("+itemExpr+"precoMercadoria-"+descontoTotal+")";
		var custoCompra = itemExpr+"precoMercadoria-"+descontoTotal+"-"+creditoICMSTotal;
		var precoVenda = '('+custoCompra+')*100/(100-'+itemExpr+'custoFrete-'+itemExpr+'debitoICMS-';
		precoVenda += itemExpr+'impostosFederais-'+itemExpr+'comissoes-'+itemExpr+'lucratividade)';
		
		$scope.$watch(precoVenda, function(i,j){
		    return function(newValue,oldValue){
			if($scope.shared.selected.itens!=undefined &&
			   $scope.shared.selected.itens[i].itens!=undefined)
			{
			    $scope.shared.selected.itens[i].itens[j].precoVenda = newValue;
			    $scope.shared.selected.itens[i].itens[j].total = newValue*$scope.shared.selected.itens[i].itens[j].quantidade;
			}
		    }
		}(i,j));


		if(totalLoteExpr!="")
		    totalLoteExpr += '+';

		totalLoteExpr += '('+itemExpr+"descartado=='1'?0:"+itemExpr+'total)';


		if(allDone!="")
		    allDone += '&&';
		allDone += '('+itemExpr+'descartado==1 || '+itemExpr+'cotacaoId!=null)';
	    }


	    $scope.$watch(totalLoteExpr, function(i){
		return function(newValue,oldValue){
		    if($scope.shared.selected.itens!=undefined)
			$scope.shared.selected.itens[i].total = newValue;
		}
	    }(i));

	    $scope.$watch(loteExpr, function(i){
		return function(newValue,oldValue){
		    if($scope.shared.selected.itens!=undefined)
			$scope.shared.selected.itens[i].checked = newValue;
		}
	    }(i));
	} // for

	$scope.allDone = false;
	$scope.$watch(allDone, function(newValue,oldValue){
	    $scope.allDone = newValue;
	});

	$scope.$watch(allExpr, function(newValue,oldValue){
	    $scope.anyItemSelected = newValue;
	});

	load.resolve();
    }, function() {
	load.reject();
    });


    var novoBtn = {fantasyName: '<b>Novo</b>'};
    $scope.getFornecedor = function(filter) {
	if(typeof(filter)=='string')
	{
	    if(filter.trim()=='')
		return [novoBtn];

	    return Fornecedores.search({
		filter: filter,
		returnFields: 'email,fantasyName',
		searchCriteria: 'fantasyName'
	    }).$promise.then(
		function(result) {
		    result.children.push(novoBtn);
		    return result.children;
		});
	}
    }

    $scope.$on('$typeahead.select', function(event, value, index, elem){
	if(value.docId)
	    return;

	var params = {
	    onSave: function(doc) {
		$scope.fornecedor = doc.attrs;
	    }
	};
	$scope.openFornecedor(params);
    });

    $scope.emailCotacao = function($event) {
	$event.preventDefault();

	var recepient = '';
	var process = $scope.shared.selected;
	if(process.docId == undefined || $scope.fornecedor == undefined)
	    return;

	recepient += $scope.fornecedor.email;

	var itens = "";
	for(var i=0; i<process.itens.length; i++)
	{
	    for(var j=0; j<process.itens[i].itens.length; j++)
	    {
		if(process.itens[i].itens[j].checked)
		{
		    itens += "Descrição: "+process.itens[i].itens[j].descr+"\n";
		    itens += "Quantidade: "+process.itens[i].itens[j].quantidade+"\n\n";
		}
		
	    }
	}
	if(itens=="")
	    return;

	var options = {
	    subject: avalancheConfig.emailCotacao.subject,
	    body: avalancheConfig.emailCotacao.mensagem + "\n\n" + itens
	};

	window.open(Mailto.url(recepient, options),'_blank');
    }

    $scope.aplicaDesconto = function(elm) {
	elm.$hide();
    }

    $scope.changeLote = function(lote){
	for(var j=0; j<lote.itens.length; j++)
	{
	    lote.itens[j].checked = lote.checked;
	    angular.element(lote.cbList[j].children()[0]).prop('checked',lote.checked);
	}
    }

    $scope.descartarProcesso = function(task) {
	Documents.save({
	    docId: $scope.shared.selected.docId
	},{
	    fase: 'descartado'
	},function(){
	    task.resolve();
	    $scope.unselect();
	},function(){
	    task.reject();
	});
    }

    $scope.descartar = function(task, item) {

	Documents.save({
	    docId: item.itemId
	}, { 
	    descartado: item.descartado==0?1:0
	}, function(doc){
	    task.resolve();
	    item.descartado = (item.descartado==0?1:0);
	}, function(resp){
	    task.reject();
	    var error = $alert({
		animation: 'am-slide-top',
		placement: 'top-right',
		title: $scope.translations['alertErrorTag']+':',
		content: resp.data.statusMessage,
		type: 'danger',
		show: true,
		container: 'body',
		duration: 5,
		dismissable: true
	    });
	});
    }

    $scope.finalizar = function() {
	$scope.finalizarCotacao($scope.shared.selected);
	$scope.unselect();
    }

    // administradores da captação/cotação podem alterar responsáveis
    var permissions = $scope.permission(['licitacoes.captacao.admin',
					 'licitacoes.cotacao.meusprocessos.admin']);
    $scope.shared.permCaptacaoAdmin = permissions[0];
    $scope.shared.permCotacaoAdmin = permissions[1];

    $scope.shared.setRespCaptacao = function(lic) {
	$scope.saveDoc(lic.docId,{respCaptacao: lic.respCaptacao.attrs.id});
    }

    $scope.shared.setRespCotacao = function(lic) {
	$scope.saveDoc(lic.docId,{respCotacao: lic.respCotacao.attrs.id});
    }

});

mod.controller('CalcPercentCtrl',function($scope){
    $scope.getOpcoes = function(field) {
	if(field.opcoes!=undefined)
	    return field.opcoes.$promise.then(
		function(result) {
		    return result.children;
		});
    }
});

mod.controller('DescontoCtrl',function($scope,$alert,$q,Documents){
    $scope.desconto = {
	attrs: {
	    fieldName: 'desconto',
	    required: 'required',
	    label: 'Desconto'
	}
    }

    $scope.values = {};

    $scope.aplicaDesconto = function($hide) {
	if(!$scope.popoverForm.$valid)
	{
	    $scope.popoverForm.submitted = true;
	    return;
	}

	$hide();
	var taskList = [];
	for(var i=0; i<$scope.shared.selected.itens.length; i++)
	{
	    for(var j=0; j <$scope.shared.selected.itens[i].itens.length; j++)
	    {
		var item = $scope.shared.selected.itens[i].itens[j];

		if(item.checked &&
		   item.cotacaoId)
		{
		    var saveTask = $q.defer();
		    taskList.push(saveTask.promise);
		    
		    Documents.save({
			docId: item.cotacaoId
		    }, $scope.values, function(saveTask,item) {
			return function(doc){
			    saveTask.resolve();
			    item.desconto = doc.attrs.desconto;
			}
		    }(saveTask,item), function(saveTask) {
			return function(resp){
			    saveTask.reject();
			    var error = $alert({
				animation: 'am-slide-top',
				placement: 'top-right',
				title: $scope.translations['alertErrorTag']+':',
				content: resp.data.statusMessage,
				type: 'danger',
				show: true,
				container: 'body',
				duration: 5,
				dismissable: true
			    });
			}
		    }(saveTask));
		}
	    }
	}

	if(taskList.length>0)
	{
	    var savingAlert = $alert({
		animation: 'am-slide-top',
		placement: 'top-right',
		title: $scope.translations['savingTag']+'...',
		type: 'info',
		show: true,
		container: 'body',
		dismissable: true
	    });
	    
	    $q.all(taskList).then(function() {
		savingAlert.hide();
	    }, function() {
		savingAlert.hide();
	    });
	}
    }
});

mod.controller('DisputaCtrl',function($scope,$rootScope,$state,$alert,$interval,Documents,avalancheConfig,UsuariosResponsaveis){
    $scope.beginTasks();
    $rootScope.activeFase = $rootScope.fases.disputa;
    if($state.current.name=='licitacoes.disputa')

	if($scope.shared.selected.docId)
	    $state.go('licitacoes.disputa.processo',
		      {docId:$scope.shared.selected.docId});
	else
	    $state.go('licitacoes.disputa.agenda');

    $scope.tabs = [];

    var tabsPerms = $scope.permission(['licitacoes.disputa.agenda',
				       'licitacoes.disputa.acompanhamento']);

    if(tabsPerms[0])
	$scope.tabs.push({ 
	    title:'Agenda de disputa', 
	    name: "agenda", 
	    template: 'agenda.html'
	});
    if(tabsPerms[1])
	$scope.tabs.push({
	    title:'Acompanhamento', 
	    name: "acompanhamento", 
	    template: 'acompanhamento.html'
	});

    $scope.$watch('tabs.activeTab',function(newValue,oldValue){
	if(oldValue != newValue && newValue != undefined)
	    $state.go('licitacoes.disputa.'+newValue);
    });

    // administradores da disputa podem alterar responsáveis
    if($scope.permission('licitacoes.disputa.admin'))
	$scope.responsaveis = UsuariosResponsaveis.query({state:'licitacoes.disputa.agenda'});

    $scope.setRespDisputa = function(lic) {
	$scope.saveDoc(lic.docId,{respDisputa: lic.respDisputa});
    }

    $scope.finalizarDisputa = function(docId) {

	var finalizandoAlert = $alert({
	    animation: 'am-slide-top',
	    placement: 'top-right',
	    title: 'Finalizando disputa',
	    template: 'finalizando.html',
	    type: 'info',
	    show: true,
	    container: 'body',
	    dismissable: true,
	    scope: $scope
	});
	var cancelInterval = $interval(function() {
	    finalizandoAlert.hide();
	    Documents.save({
		docId: docId
	    },{
		fase: 'arquivo'
	    }, function(doc){
		$rootScope.loadBusca();
	    }, function(resp) {
		var error = $alert({
		    animation: 'am-slide-top',
		    placement: 'top-right',
		    title: $scope.translations['alertErrorTag']+':',
		    content: resp.data.statusMessage,
		    type: 'danger',
		    show: true,
		    container: 'body',
		    duration: 5,
		    dismissable: true
		});
	    });
	},avalancheConfig.finalizarTimeout,1);

	$scope.cancelarFinaliza = function($event) {
	    $event.preventDefault();
	    $interval.cancel(cancelInterval);
	    finalizandoAlert.hide();	    
	    $state.go('licitacoes.disputa.processo',{docId:docId});
	}
    }

});


mod.controller('AgendaCtrl',function($rootScope,$scope,Agenda){
    $scope.tabs.activeTab = 'agenda';

    $scope.setBusca({
	loadBusca: function() {
	    $scope.beginTasks();
	    var load = $scope.pushTask();
	    $scope.watchTasks();
	    var data = Agenda.search($scope.busca,function() {
		$rootScope.docList = data;
		load.resolve();
	    }, function() {
		load.reject();
	    });
	}
    });
});

mod.controller('AcompanhamentoCtrl',function($rootScope,$scope,Acompanhamento){
    $scope.tabs.activeTab = 'acompanhamento';

    $scope.setBusca({
	loadBusca: function() {
	    $scope.beginTasks();
	    var load = $scope.pushTask();
	    $scope.watchTasks();
	    var data = Acompanhamento.search($scope.busca,function() {
		$rootScope.docList = data;
		load.resolve();
	    }, function() {
		load.reject();
	    });
	}
    });

});

mod.controller('DisputaProcessoCtrl',function($scope,$stateParams,$alert,Disputa,Documents,DocumentoComumParser,UsuariosResponsaveis,avalancheConfig){
    $scope.beginTasks();

    $scope.shared.pathComentarios = '/comentarios-disputa/';

    $scope.setBusca({ state: $scope.disputaAgenda});

    $scope.shared.selected = {};
    var load = $scope.pushTask();
    $scope.watchTasks();
    var data = Disputa.get({docId:$stateParams['docId']},function() {
	$scope.shared.selected = data;

	// monitora lotes e itens para contas
	var allDone = "";
	for(var i=0; i<$scope.shared.selected.itens.length; i++)
	{
	    var totalLoteExpr = "";
	    for(var j=0; j <$scope.shared.selected.itens[i].itens.length; j++)
	    {
		var item = $scope.shared.selected.itens[i].itens[j];
		if(!item.cotacaoId)
		{
		    item.precoMercadoria = "0";
		    item.desconto = '0';
		    item.creditoICMS = '0';
		    item.custoFrete = '0';
		    item.debitoICMS = '0';
		    item.impostosFederais = '0';
		    item.comissoes = '0';
		    item.lucratividade = '0';
		}

		var itemExpr = 'shared.selected.itens['+i+'].itens['+j+'].';

		var descontoTotal = itemExpr+"desconto/100*"+itemExpr+"precoMercadoria";
		var creditoICMSTotal = itemExpr+"creditoICMS/100*("+itemExpr+"precoMercadoria-"+descontoTotal+")";
		var custoCompra = itemExpr+"precoMercadoria-"+descontoTotal+"-"+creditoICMSTotal;
		var precoVenda = '('+custoCompra+')*100/(100-'+itemExpr+'custoFrete-'+itemExpr+'debitoICMS-';
		precoVenda += itemExpr+'impostosFederais-'+itemExpr+'comissoes-'+itemExpr+'lucratividade)';

		$scope.$watch(precoVenda, function(i,j){
		    return function(newValue,oldValue){
			if($scope.shared.selected.itens!=undefined &&
			   $scope.shared.selected.itens[i].itens!=undefined)
			{
			    $scope.shared.selected.itens[i].itens[j].precoVenda = newValue;
			    $scope.shared.selected.itens[i].itens[j].total = newValue*$scope.shared.selected.itens[i].itens[j].quantidade;
			}
		    }
		}(i,j));

		if(totalLoteExpr!="")
		    totalLoteExpr += '+';

		totalLoteExpr += itemExpr+'total';

		if(allDone!="")
		    allDone += '&&';
		allDone += itemExpr+'resultadoId!=null';

		var lucrFinal = '(100-'+itemExpr+'custoFrete-'+itemExpr+'debitoICMS-';
		lucrFinal += itemExpr+'impostosFederais-'+itemExpr+'comissoes)-';
		lucrFinal +=  '('+custoCompra+')*100/'+itemExpr+'valor';

		$scope.$watch(lucrFinal, function(i,j){
		    return function(newValue,oldValue){
			if($scope.shared.selected.itens!=undefined &&
			   $scope.shared.selected.itens[i].itens!=undefined)
			    $scope.shared.selected.itens[i].itens[j].lucrFinal = newValue;
		    }
		}(i,j));
	    }
	    
	    $scope.$watch(totalLoteExpr, function(i){
		return function(newValue,oldValue){
		    if($scope.shared.selected.itens!=undefined)
			$scope.shared.selected.itens[i].total = newValue;
		}
	    }(i));
	} // for

	$scope.allDone = false;
	$scope.$watch(allDone, function(newValue,oldValue){
	    $scope.allDone = newValue;
	});

	for(var i=0; i<$scope.shared.selected.documentos.length; i++)
	    $scope.shared.selected.documentos[i] = DocumentoComumParser($scope.shared.selected.documentos[i]);

	load.resolve();
    }, function() {
	load.reject();
    });

    $scope.procTabs = [
	{title:'Itens', template: 'disputaItens.html'},
	{title:'Documentos', template: 'disputaDocumentos.html'}
    ];
    $scope.procTabs.activeTab = 0;

    var onOpenResultado = function(scope){
	// novo campo contendo descrição do item
	scope.doc.children.unshift({
	    attrs: {
		value: scope.docModalParams.descr,
		type: 'itemFixedTitle'
	    }
	});

	// para nova cotação, ajusta valores padrão 
	if(!scope.doc.attrs.docId)
	{
	    scope.doc.attrs.licitacaoId = $scope.shared.selected.docId;
	    scope.doc.attrs.itemId = scope.docModalParams.itemId;
	}

	scope.$watch('doc.attrs.posicao', function(newValue,oldValue) {
	    scope.doc.fields.vencedora.attrs.required = newValue!=1?'required':'';
	    scope.doc.fields.modelo.attrs.required = newValue!=1?'required':'';
	});
    }

    $scope.openResultado = function(item) {
	$scope.openDocModal({ 
	    docId: item.resultadoId, 
	    path: avalancheConfig.path.resultados,
	    title: 'Resultado',
	    excludeFields: ['userId','groupId','userRight','groupRight',
			    'otherRight','creationDate','lastChanged',
			    'lastChangedUserId','keywords','itemId'],
	    descr: item.descr,
	    itemId: item.itemId,
	    onOpen: onOpenResultado,
	    onSave: function (doc) {
		item.posicao = doc.attrs.posicao;
		item.resultadoId = doc.attrs.docId;
		item.valor = doc.attrs.valor;
	    },
	    buttons: { remove: false, history: true }
	});
    }


    $scope.setAcompanhamento = function() {
	$scope.saveDoc($scope.shared.selected.docId,{
	    acompanhamento: $scope.shared.selected.acompanhamento
	},undefined,function() {
	    $scope.shared.selected.acompanhamento = 
		$scope.shared.selected.acompanhamento=='1'?'0':'1';
	});
    }

    $scope.enviarCotacao = function(task) {
	Documents.save({
	    docId: $scope.shared.selected.docId,
	},{fase:'cotacao'}, function(doc) {
	    task.resolve();
	},function(resp) {
	    task.reject();
	    var error = $alert({
		animation: 'am-slide-top',
		placement: 'top-right',
		title: 'ERRO: ',
		content: resp.data.statusMessage,
		type: 'warning',
		show: true,
		container: 'body',
		duration: 5,
		dismissable: true
	    });	    
	});
	$scope.unselect();
    }

    $scope.setCadastrado = function(item) {
	$scope.saveDoc(item.cotacaoId,{
	    cadastrado: item.cadastrado
	},undefined,function(){
	    item.cadastrado = item.cadastrado=='1'?'0':'1';
	});
    }

    $scope.finalizando = false;
    $scope.finalizarClick = function() {
	switch($scope.shared.selected.fase) {
	case 'disputa':
	    $scope.finalizarDisputa($scope.shared.selected.docId);
	    $scope.unselect();
	    break;
	case 'arquivo':
	    $scope.finalizando = true;
	    var savingAlert = $alert({
		animation: 'am-slide-top',
		placement: 'top-right',
		title: $scope.translations['savingTag']+'...',
		type: 'info',
		show: true,
		container: 'body',
		dismissable: true
	    });
	    $scope.shared.selected.fase = 'disputa';
	    Documents.save({
		docId: $scope.shared.selected.docId
	    },{
		fase: 'disputa'
	    }, function(doc){
		savingAlert.hide();
		$scope.finalizando = false;
	    }, function(resp) {
		savingAlert.hide();
		$scope.finalizando = false;
		$scope.shared.selected.fase = 'arquivo';
		var error = $alert({
		    animation: 'am-slide-top',
		    placement: 'top-right',
		    title: $scope.translations['alertErrorTag']+':',
		    content: resp.data.statusMessage,
		    type: 'danger',
		    show: true,
		    container: 'body',
		    duration: 5,
		    dismissable: true
		});
	    });
	}
	
    }

    // administradores da cotação/disputa podem alterar responsáveis
    var permissions = $scope.permission(['licitacoes.disputa.admin',
					 'licitacoes.cotacao.meusprocessos.admin']);
    $scope.shared.permDisputaAdmin = permissions[0];
    $scope.shared.permCotacaoAdmin = permissions[1];

    $scope.shared.setRespCotacao = function(lic) {
	$scope.saveDoc(lic.docId,{respCotacao: lic.respCotacao.attrs.id});
    }

    $scope.shared.setRespDisputa = function(lic) {
	$scope.saveDoc(lic.docId,{respDisputa: lic.respDisputa.attrs.id});
    }


});

mod.controller('DisputaProcessoDocsCtrl',function($scope,$rootScope,$interval,Documentos,DocumentosDisputa,BuscaArquivosProcessos,DocumentoComumParser,$alert,avalancheConfig){

    $scope.anexarNovoArquivo = function(lista) {
	var params = { 
	    path:  avalancheConfig.path.documentos,
	    onSave: function(doc) {
		if(lista=='documentos')
		    doc.attrs.deletable = '1';
		eval('$scope.shared.selected.'+lista+'.push(doc.attrs)');
	    },
	    onOpen: function(scope) {
		scope.doc.attrs.licitacaoId = $scope.shared.selected.docId,
		scope.doc.attrs.tipo = lista
	    },
	    Documents: Documentos
	}	    
	$scope.openArquivo(params);
    }

    $scope.removerArquivo = function(lista, $index) {
	var documentos = eval('$scope.shared.selected.'+lista);
	var undoData = documentos.splice($index,1);
	var alertScope = $scope.$new();
	var deletingAlert = $alert({
	    animation: 'am-slide-top',
	    placement: 'top-right',
	    title: $scope.translations['alertWarningTag']+':',
	    template: 'avAlertDelete.html',
	    type: 'warning',
	    show: true,
	    container: 'body',
	    dismissable: true,
	    scope: alertScope
	});
	var deleteInterval = $interval(function() {
	    deletingAlert.hide();
	    alertScope.$destroy();
	    Documentos.delete({
		docId: undoData[0].docId,
		path: avalancheConfig.path.documentos,
		licitacaoId: $scope.shared.selected.docId
	    },function(){},function(resp) {
		documentos.splice($index,0,undoData[0]);
		var error = $alert({
		    animation: 'am-slide-top',
		    placement: 'top-right',
		    title: $scope.translations['alertErrorTag']+':',
		    content: resp.data.statusMessage,
		    type: 'danger',
		    show: true,
		    container: 'body',
		    duration: 5,
		    dismissable: true
		});
	    });
	},avalancheConfig.deleteTimeout,1);

	alertScope.undoDelete = function($event) {
	    $event.preventDefault();
	    $interval.cancel(deleteInterval);
	    deletingAlert.hide();
	    documentos.splice($index,0,undoData[0]);
	    alertScope.$destroy();
	}
    }

    $scope.getArquivo = function(filter) {
	if(typeof(filter)=='string')
	{
	    var novoBtn = {nome: '<b>Novo upload</b>'};
	    if(filter.trim()=='')
		return [novoBtn];

	    return BuscaArquivosProcessos.search({filter: filter, limit: 6}).$promise.then(
		function(result) {
		    result.children.push(novoBtn);
		    return result.children;
		});
	}
    }

    $scope.anexarArquivo = function(docId) {
	if(!docId)
	    $scope.anexarNovoArquivo('documentos');
	else {
	    $scope.beginTasks();
	    var load = $scope.pushTask();
	    $scope.watchTasks();
	    var newDoc = new DocumentosDisputa();
	    newDoc.$save({
		licitacaoId: $scope.shared.selected.docId,
		docId: docId
	    },function(doc){
		doc.deletable = '1';
		$scope.shared.selected.documentos.push(doc);
		load.resolve();
	    },function(resp){
		var error = $alert({
		    animation: 'am-slide-top',
		    placement: 'top-right',
		    title: 'ERRO: ',
		    content: resp.data.statusMessage,
		    type: 'warning',
		    show: true,
		    container: 'body',
		    duration: 5,
		    dismissable: true
		});
		load.reject();
	    });
	}

    }

    $scope.openDocComum = function($index) {
	var params = { 
	    docId: $scope.shared.selected.documentos[$index].docId, 
	    path: avalancheConfig.path.docsComuns,
	    onSave: function(doc) {
		$scope.shared.selected.documentos[$index] = DocumentoComumParser(doc.attrs);
	    }
	}
	    
	$scope.openArquivo(params);
    }

});

mod.controller('PropostaCtrl', function($scope,$alert,Proposta) {
    $scope.aumento = {
	attrs: {
	    fieldName: 'aumento',
	    required: 'required',
	    label: 'Aumento'
	}
    }
    $scope.condicoes = {
	attrs: {
	    fieldName: 'condicoes',
	    required: 'required',
	    label: 'Condições'
	}
    }

    $scope.values = {
	condicoes: $scope.config.children[0].condicoesProposta
    };

    if($scope.shared.selected.eletronico==0)
    {
	$scope.respProposta = {
	    attrs: {
		fieldName: 'respProposta',
		required: 'required',
		label: 'Responsável'
	    }
	}
	$scope.values.respProposta = $scope.config.children[0].respPropostaPR;
    }

    $scope.gerarProposta = function($hide){
	if(!$scope.popoverForm.$valid)
	{
	    $scope.popoverForm.submitted = true;
	    return;
	}

	$hide();
	var savingAlert = $alert({
	    animation: 'am-slide-top',
	    placement: 'top-right',
	    title: 'Gerando proposta...',
	    type: 'info',
	    show: true,
	    container: 'body',
	    dismissable: true
	});

	$scope.beginTasks();
	var load = $scope.pushTask();
	$scope.watchTasks();
	Proposta.save({ 
	    licitacaoId: $scope.shared.selected.docId
	}, $scope.values, function(doc){
	    savingAlert.hide();
	    doc.deletable = '1';
	    $scope.shared.selected.documentos.push(doc);
	    load.resolve();
	},function(resp){
	    savingAlert.hide();
	    var error = $alert({
		animation: 'am-slide-top',
		placement: 'top-right',
		title: 'ERRO: ',
		content: resp.data.statusMessage,
		type: 'warning',
		show: true,
		container: 'body',
		duration: 5,
		dismissable: true
	    });
	    load.reject();
	});
    }

});

mod.controller('LogisticaCtrl',function($rootScope){
    $rootScope.activeFase = $rootScope.fases.logistica;
});

mod.controller('FornecedoresCtrl',function($scope,$rootScope,Fornecedores){
    $scope.setBusca( {
	loadBusca: function() {
	    $scope.beginTasks();
	    
	    var load = $scope.pushTask();
	    $scope.watchTasks();
	    var data = Fornecedores.search($scope.busca,function() {
		$rootScope.docList = data;
		load.resolve();
	    }, function() {
		load.reject();
	    });
	}
    });


    $scope.openFornecedorBusca = function($index) {
	var params = { 
	    docId: $index!=undefined?$rootScope.docList.children[$index].docId:undefined, 
	    onSave: function(doc) {
		if($index!=undefined)
		    $rootScope.docList.children[$index] = doc.attrs;
		else
		{
		    if($scope.busca.offset>0 ||
		       $scope.busca.filter != undefined 
		       && $scope.busca.filter.trim() != '')
		    {
			$scope.busca.filter = undefined;
			$scope.busca.date = undefined;
			$scope.busca.offset = 0;
			$scope.busca.usuario = undefined;
			$rootScope.docList = undefined;
			$rootScope.loadBusca();
		    }
		    else
		    {
			$rootScope.docList.children.unshift(doc.attrs);
			if($rootScope.docList.totalResults>=$scope.busca.limit)
			    $rootScope.docList.children.pop();
		    }
		}
	    },
	    onBeforeDelete: function () {
		return $rootScope.docList.children.splice($index,1);
	    },
	    onUndoDelete: function (undoData) {
		$rootScope.docList.children.splice($index,0,undoData[0]);
	    },
	    onDelete: function() {
		if($rootScope.docList.totalResults>$scope.busca.offset+$scope.busca.limit)
		    $rootScope.loadBusca();
	    }
	}
	    
	$scope.openFornecedor(params);
    }

});

mod.controller('DocumentosCtrl',function($rootScope,$scope,$alert,$interval,Documents,DocumentosComuns,DocumentoComumParser,avalancheConfig){
    $scope.removerArquivo = function($index) {
	var undoData = $rootScope.docList.children.splice($index,1);
	var alertScope = $scope.$new();
	var deletingAlert = $alert({
	    animation: 'am-slide-top',
	    placement: 'top-right',
	    title: $scope.translations['alertWarningTag']+':',
	    template: 'avAlertDelete.html',
	    type: 'warning',
	    show: true,
	    container: 'body',
	    dismissable: true,
	    scope: alertScope
	});
	var deleteInterval = $interval(function() {
	    deletingAlert.hide();
	    alertScope.$destroy();
	    Documents.delete({
		docId: undoData[0].docId,
		path: avalancheConfig.path.docsComuns
	    },function(){
		if($rootScope.docList.totalResults>$scope.busca.offset+$scope.busca.limit)
		    $rootScope.loadBusca();
	    }, function(resp) {
		$rootScope.docList.children.splice($index,0,undoData[0]);
		var error = $alert({
		    animation: 'am-slide-top',
		    placement: 'top-right',
		    title: $scope.translations['alertErrorTag']+':',
		    content: resp.data.statusMessage,
		    type: 'danger',
		    show: true,
		    container: 'body',
		    duration: 5,
		    dismissable: true
		});
	    });
	},avalancheConfig.deleteTimeout,1);

	alertScope.undoDelete = function($event) {
	    $event.preventDefault();
	    $interval.cancel(deleteInterval);
	    deletingAlert.hide();
	    $rootScope.docList.children.splice($index,0,undoData[0]);
	    alertScope.$destroy();
	}
    }

    $scope.setBusca( {
	loadBusca: function() {
	    $scope.beginTasks();
	    var load = $scope.pushTask();
	    $scope.watchTasks();
	    var data = DocumentosComuns.search($scope.busca,function() {
		$rootScope.docList = data;
		load.resolve();
	    }, function() {
		load.reject();
	    });
	}
    });

    $scope.openDocComum = function($index) {
	var params = { 
	    docId: $index!=undefined?$rootScope.docList.children[$index].docId:undefined, 
	    path: avalancheConfig.path.docsComuns,
	    onSave: function(doc) {
		if($index!=undefined)
		    $rootScope.docList.children[$index] = DocumentoComumParser(doc.attrs);
		else
		{
		    if($scope.busca.offset>0 ||
		       $scope.busca.filter != undefined 
		       && $scope.busca.filter.trim() != '')
		    {
			$scope.busca.filter = undefined;
			$scope.busca.date = undefined;
			$scope.busca.offset = 0;
			$scope.busca.usuario = undefined;
			$rootScope.docList = undefined;
			$rootScope.loadBusca();
		    }
		    else
		    {
			$rootScope.docList.children.unshift(DocumentoComumParser(doc.attrs));
			if($rootScope.docList.totalResults>=$scope.busca.limit)
			    $rootScope.docList.children.pop();
		    }
		}
	    }
	}
	    
	$scope.openArquivo(params);
    }

});

mod.controller('RelatoriosCtrl',function($scope){
});
