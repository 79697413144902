var mod = angular.module('avalanche.ctrls', ['avalanche.services','ngFileUpload']);

mod.config(function($translatePartialLoaderProvider) {
    $translatePartialLoaderProvider.addPart('form');
});

mod.controller('LoadingCtrl',function($scope,$q){
    var taskList = [];
    $scope.loading = false;

    $scope.beginTasks = function() {
	if(!$scope.loading)
	{
	    taskList = [];
	    $scope.loading = true;
	}
    }

    $scope.pushTask = function() {
	var task = $q.defer();
	taskList.push(task.promise);
	return task;
    }

    $scope.watchTasks = function() {
	$q.all(taskList).then(function(result) {
	    $scope.loading = false;
	}, function() {
	    $scope.loading = false;
	});
    };
});

mod.controller('LoginFormCtrl', function ($rootScope,$scope,$urlRouter,$alert,Login){

    $scope.loginData = {};

    // Perform the login action when the user submits the login form
    $scope.submit = function() {
	if(!$scope.loginForm.$valid)
	{
	    $scope.loginForm.submitted = true;
	    return;
	}

	// login code
	$scope.beginTasks();
	var load = $scope.pushTask();
        $scope.watchTasks();
	Login.save($scope.loginData, function(value, responseHeaders){
	    load.resolve();
	    $rootScope.session = value;
	},function(httpResponse){
	    load.reject();
	    var error = $alert({
		animation: 'am-slide-top',
		placement: 'top-right',
		title: $scope.translations['alertErrorTag']+':',
		content: httpResponse.data.statusMessage,
		type: 'danger',
		show: true,
		container: 'body',
		duration: 5,
		dismissable: true
	    });	    
	});
    };
});

mod.controller('LibraryCtrl', function($scope,$modal,$interval,Documents,History,avalancheConfig,$alert,$translate){
    $translate(['alertErrorTag','alertWarningTag','savingTag','uploadingTag','trueTag','falseTag']).then(function (translations) {
	$scope.translations = translations;
    });

    $scope.openDocModal = function (params) {

	if(typeof(params)==undefined)
	    params = {};
	if(params.Documents==undefined)
	    params.Documents = Documents;

	$scope.beginTasks();
	var load = $scope.pushTask();
        $scope.watchTasks();
	var data = params.Documents.get({
	    docId: params.docId,
	    path: params.path 
	},function() {
	    var scope = $scope.$new();
	    scope.doc = data;
	    scope.doc.attrs.path = params.path;
	    scope.doc.fields = {};
	    for(var i=data.children.length-1; i>=0;i--)
	    {
		// field by name refs
		eval("scope.doc.fields."+data.children[i].attrs.fieldName+
		     "=data.children[i]");
		if(data.children[i].attrs.type=='objectList')
		{
		    eval("scope.doc.fields."+data.children[i].attrs.fieldName+
			 ".fields={}");
		    for(var j=0; j<data.children[i].children.length;j++)
			eval("scope.doc.fields."+
			     data.children[i].attrs.fieldName+".fields."+
			     data.children[i].children[j].attrs.fieldName+
			     "=data.children[i].children[j]");
		}

		// exclude some fields
		if(params.excludeFields && 
		   params.excludeFields.indexOf(data.children[i].attrs.fieldName)!=-1)
		    data.children.splice(i,1);
	    }

	    // exclude some fields - values
	    if(params.excludeFields)
		for(var i=0;i<params.excludeFields.length;i++)
		    delete scope.doc.attrs[params.excludeFields[i]];

	    scope.docModalParams = params;

	    // on open
	    if(typeof(params.onOpen)=='function')
		params.onOpen(scope);

	    // dirty control
	    scope.docModalParams.dirty = false;
	    scope.saving = false;

	    if(params.buttons == undefined)
	    {
		scope.buttons = {
		    history: true,
		    remove: true
		}
	    } else
		scope.buttons = params.buttons;

	    // show modal
	    var docModal = $modal({
		contentTemplate: 'docModal.html',
		scope: scope,
		animation: 'am-fade-and-scale',
		prefixEvent: 'av.doc.modal'
	    });

	    var destroy = function() {
		docModal.destroy();
		scope.$destroy();
	    }

	    scope.save = function() {
		// don't save if it isn't needed
		if(!scope.docModalParams.dirty)
		{
		    docModal.hide();
		    return;
		}

		scope.saving = true;
		docModal.hide();

		var fields = (typeof(scope.docModalParams.extraFields)=='object') ? 
		    angular.extend({}, scope.doc.attrs, scope.docModalParams.extraFields) : scope.doc.attrs;

		var savingAlert = $alert({
		    animation: 'am-slide-top',
		    placement: 'top-right',
		    title: $scope.translations['savingTag']+'...',
		    type: 'info',
		    show: true,
		    container: 'body',
		    dismissable: true
		});

		params.Documents.save({
		    docId: scope.docModalParams.docId,
		    path: scope.docModalParams.path 
		},fields,function(doc){
		    // documento salvo
		    savingAlert.hide();
		    destroy();
		    if(typeof(params.onSave)=='function')
			params.onSave(doc);
		}, function(resp) {
		    scope.saving = false;
		    savingAlert.hide();
		    docModal.show();
		    var error = $alert({
			animation: 'am-slide-top',
			placement: 'top-right',
			title: $scope.translations['alertErrorTag']+':',
			content: resp.data.statusMessage,
			type: 'danger',
			show: true,
			container: 'body',
			duration: 5,
			dismissable: true
		    });
		});
	    }

	    scope.delete = function() {
		scope.saving = true;
		docModal.hide();

		var undoData;
		if(typeof(params.onBeforeDelete)=='function')
		    undoData = params.onBeforeDelete();

		var deletingAlert = $alert({
		    animation: 'am-slide-top',
		    placement: 'top-right',
		    title: $scope.translations['alertWarningTag']+':',
		    template: 'avAlertDelete.html',
		    type: 'warning',
		    show: true,
		    container: 'body',
		    dismissable: true,
		    scope: scope
		});
		var deleteInterval = $interval(function() {
		    deletingAlert.hide();
		    params.Documents.delete({
			docId: scope.docModalParams.docId,
			path: scope.docModalParams.path 
		    },function(){
			// documento removido
			destroy();
			if(typeof(params.onDelete)=='function')
			    params.onDelete();
		    }, function(resp) {
			scope.saving = false;
			docModal.show();
			var error = $alert({
			    animation: 'am-slide-top',
			    placement: 'top-right',
			    title: $scope.translations['alertErrorTag']+':',
			    content: resp.data.statusMessage,
			    type: 'danger',
			    show: true,
			    container: 'body',
			    duration: 5,
			    dismissable: true
			});
		    });
		},avalancheConfig.deleteTimeout,1);

		scope.undoDelete = function($event) {
		    $event.preventDefault();
		    $interval.cancel(deleteInterval);
		    scope.saving = false;
		    deletingAlert.hide();
		    docModal.show();
		    if(typeof(params.onUndoDelete)=='function')
			params.onUndoDelete(undoData);
		}

	    }

	    scope.$on('av.doc.modal.hide',function(){
		if(!scope.saving)
		{
		    if(scope.docModalParams.dirty)
		    {
			var error = $alert({
			    animation: 'am-slide-top',
			    placement: 'top-right',
			    title: $scope.translations['alertWarningTag']+':',
			    template: 'avDiscard.html',
			    type: 'warning',
			    show: true,
			    container: 'body',
			    dismissable: true,
			    scope: scope
			});
			var discartInterval = $interval(function() {
			    error.hide();
			    destroy();
			},avalancheConfig.discardTimeout,1);
			scope.undoDiscard = function() {
			    $interval.cancel(discartInterval);
			    error.hide();
			    docModal.show();
			}

		    }
		    else
			destroy();
		}
	    });

	    if(params.docId)
	    {
		var history = History.query({ 
		    docId: params.docId
		}, function() {
		    scope.history = history;
		});
	    }

	    load.resolve();

	}, function(){
	    load.reject();
	});

    }
});

mod.controller('DocModalCtrl',function($scope,$controller){
    $scope.showHistory=false;

    if($scope.docModalParams.controller)
	$controller($scope.docModalParams.controller, {$scope: $scope});

    $scope.$watch('avForm.$dirty', function (newValue, oldValue) {
	if(!$scope.docModalParams.dirty && newValue==true)
	    $scope.docModalParams.dirty = true;
    });

    $scope.aSortableListeners = {
	dragMove: function(event) {
	    $scope.dragMove(event);
	},
	dragEnd: function(event) {
	    $scope.dragEnd(event);
	}
    }
});

mod.controller('HistoryCtrl',function($scope,HistoryFormatter){
    var field;
    var parentField = null;
    if($scope.item.parentField)
    {
	parentField = eval('$scope.doc.fields.'+$scope.item.parentField);
	field = eval('parentField.fields.'+$scope.item.fieldName);
	$scope.label = parentField.attrs.label+' > '+field.attrs.label;
    }
    else
    {
	field = eval('$scope.doc.fields.'+$scope.item.fieldName);
	$scope.label = field.attrs.label;
    }
    $scope.value = HistoryFormatter.format(field,$scope.item.value,$scope.translations);
    $scope.field = field;
});
