var mod = angular.module('avalanche.services', ['ngResource','avalanche.config']);

mod.factory('Menu', function($resource,avalancheConfig) {
    return $resource(avalancheConfig.backendAddress+'/menu/');
});

mod.factory('States', function($resource,avalancheConfig) {
    return $resource(avalancheConfig.backendAddress+'/states/',null,
		     { search:  {method:'POST'} });
});

mod.factory('Documents', function($resource,avalancheConfig) {
    return $resource(avalancheConfig.backendAddress+'/documents/:docId?path=:path&fieldName=:fieldName&sourceId=:sourceId');
});

mod.factory('Search', function($resource,avalancheConfig) {
    return $resource(avalancheConfig.backendAddress+'/search',null,
		     { search:  {method:'POST'}});
});

mod.factory('List', function($resource,avalancheConfig) {
    return $resource(avalancheConfig.backendAddress+'/list',null,
		     { search:  {method:'POST'}});
});

mod.factory('Login', function($resource,avalancheConfig) {
    return $resource(avalancheConfig.backendAddress+'/login/');
});

mod.factory('Logout', function($resource,avalancheConfig) {
    return $resource(avalancheConfig.backendAddress+'/logout/');
});

mod.factory('Session', function($resource,avalancheConfig) {
    return $resource(avalancheConfig.backendAddress+'/session/');
});

mod.factory('Cep',function($resource,avalancheConfig) {
    return $resource(avalancheConfig.backendAddress+'/cep/:cep');
});

mod.factory('History',function($resource,avalancheConfig) {
    return $resource(avalancheConfig.backendAddress+'/history/:docId');
});

mod.factory('HistoryFormatter', function($filter,$locale,avalancheConfig) {
    var obj = {};

    obj.formatCnpj = function(value) {
        return value.substr(0,2) + '.' + value.substr(2,3) + '.' + 
	    value.substr(5,3) + '/' + value.substr(8,4) + '-'+
            value.substr(12,14);
    }

    obj.formatCep = function(value) {
	return value.substr(0,5)+'-'+value.substr(5,3);
    }

    obj.formatSelect = function(field, value) {
	for(var i=0;i<field.children.length;i++)
	    if(field.children[i].attrs.id==value)
		return field.children[i].attrs.value;
    }

    obj.formatDatetime = function (value) {
	return $filter('date')($filter('mysqlDatetimeToISO')(value),
			       avalancheConfig.datetimeFormat);
    }

    obj.formatDate = function(value) {
	return $filter('date')($filter('mysqlDatetimeToISO')(value),
			       avalancheConfig.dateFormat);
    }

    obj.formatCurrency = function(value) {
	return $locale.NUMBER_FORMATS.CURRENCY_SYM+$filter('number')(value, 2);
    }

    obj.formatPercent = function(value) {
	return $filter('number')(value, 2)+'%';
    }

    obj.formatBoolean = function(value,translations) {
	return translations[value==1?'trueTag':'falseTag'];
    }

    obj.formatObjectList = function(field,value) {
	var newValue = '"';
	for(var j=0;j<value.length;j++)
	{
	    if(j>0)
		newValue += '; "';
	    
	    var first = true;
	    for(var i=0;i<field.children.length;i++)
	    {
		var fieldValue = eval('obj.format(field.children[i],value[j].'+
				      field.children[i].attrs['fieldName']+')');
		if(fieldValue)
		{
		    if(first)
			first = false;
		    else
			newValue += ', ';
		    newValue += fieldValue;
		}
	    }
	    newValue += '"';
	}
	return newValue;
    }

    obj.format = function (field,value,translations)  {

	switch(field.attrs.type)
	{
	case 'cep':
	    return obj.formatCep(value);

	case 'cnpj':
	    return obj.formatCnpj(value);

	case 'select':
	    return obj.formatSelect(field,value);

	case 'datetime':
	    return obj.formatDatetime(value);

	case 'date':
	    return obj.formatDate(value);

	case 'currency':
	    return obj.formatCurrency(value);

	case 'percent':
	    return obj.formatPercent(value);

	case 'text': case 'integer':
	    return value;

	case 'boolean':
	    return obj.formatBoolean(value,translations);

	case 'objectList':
	    return obj.formatObjectList(field,value);
	}
    }

    return obj;
});

mod.factory('CalcDigitosPosicoes', function() {

    /*
      calc_digitos_posicoes
      
      Multiplica dígitos vezes posições
      
      @param string digitos Os digitos desejados
      @param string posicoes A posição que vai iniciar a regressão
      @param string soma_digitos A soma das multiplicações entre posições e dígitos
      @return string Os dígitos enviados concatenados com o último dígito
    */
    var calc_digitos_posicoes = function( digitos, posicoes, soma_digitos) {
	if(posicoes==undefined)
	    posicoes=10;
	if(soma_digitos==undefined)
	    soma_digitos=0;
	
	// Garante que o valor é uma string
	digitos = digitos.toString();
	
	// Faz a soma dos dígitos com a posição
	// Ex. para 10 posições:
	//   0    2    5    4    6    2    8    8   4
	// x10   x9   x8   x7   x6   x5   x4   x3  x2
	//   0 + 18 + 40 + 28 + 36 + 10 + 32 + 24 + 8 = 196
	for ( var i = 0; i < digitos.length; i++  ) {
            // Preenche a soma com o dígito vezes a posição
            soma_digitos = soma_digitos + ( digitos[i] * posicoes );
	    
            // Subtrai 1 da posição
            posicoes--;
	    
            // Parte específica para CNPJ
            // Ex.: 5-4-3-2-9-8-7-6-5-4-3-2
            if ( posicoes < 2 ) {
		// Retorno a posição para 9
		posicoes = 9;
            }
	}
	
	// Captura o resto da divisão entre soma_digitos dividido por 11
	// Ex.: 196 % 11 = 9
	soma_digitos = soma_digitos % 11;
	
	// Verifica se soma_digitos é menor que 2
	if ( soma_digitos < 2 ) {
            // soma_digitos agora será zero
            soma_digitos = 0;
	} else {
            // Se for maior que 2, o resultado é 11 menos soma_digitos
            // Ex.: 11 - 9 = 2
            // Nosso dígito procurado é 2
            soma_digitos = 11 - soma_digitos;
	}
	
	// Concatena mais um dígito aos primeiro nove dígitos
	// Ex.: 025462884 + 2 = 0254628842
	var cpf = digitos + soma_digitos;
	
	// Retorna
	return cpf;
    
    } // calc_digitos_posicoes
    return calc_digitos_posicoes;
});

mod.factory('Config', function(Search,avalancheConfig) {
    return {
	search: function(success,error) {
	    if(success==undefined)
		success = function(){};
	    if(error==undefined)
		error = function(){};

	    return Search.search({ 
		path: avalancheConfig.path.config,
		limit: 1
	    },success,error);
	}
    }
});

mod.provider('RuntimeStates', function RuntimeStates($stateProvider,$urlRouterProvider) {
    this.stateList = [];
    var provider = this;
    this.$get = function() {
	return {
	    addState: function(name, state){
		if(provider.stateList.indexOf(name)==-1)
		{
		    provider.stateList.push(name);
		    $stateProvider.state(name,state);
		}
	    },
	    otherwise: function(url){
		$urlRouterProvider.otherwise(url);		
	    }
	}
    }
});
