var mod = angular.module('Solinox.filters',[])

mod.filter('typeaheadHighlight', function() {
    function escapeRegexp(queryToEscape) {
	return queryToEscape.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
    }
    
    return function(matchItem, query) {
	return query && typeof(query)=='string' ? ('' + matchItem).replace(new RegExp(escapeRegexp(query), 'gi'), '<strong>$&</strong>') : matchItem;
    };
});

