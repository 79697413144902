var mod = angular.module('avalanche.config',[]);

mod.constant('avalancheConfig', {  

    //backendAddress: "http://192.168.2.114:8080/paeweb-backend/home",
    //backendAddress: "http://192.168.1.109:8080/paeweb-backend/home",
    //backendAddress: "http://192.168.0.2:8080/paeweb-backend/home",
    //backendAddress: "http://143.106.67.54:8080/paeweb-backend/home",
    //backendAddress: "/paeweb-backend/home",
    backendAddress: "api",
    //googleSenderId: '217540633934',
    //refreshInterval: "2000" 

    fases: {
	selecao: {value: 1, label: "seleção" },
	descartado: {value: 2, label: "descartado" },
 	captacao: {value: 3, label: "captação" },
	cotacao: {value: 4, label: "cotação" },
	disputa: {value: 5, label: "disputa" },
	logistica: {value: 6, label: "logística" },
	arquivo: {value: 7, label: "arquivo"}
    },

    emailCotacao: {
	subject: "Solicitação de orçamento",
	mensagem: "Caro fornecedor, gostaríamos de solicitar orçamento para os seguintes produtos abaixo descritos:"
    },

    busca: {
	limit: 10
    },

    // caminho na lib do avalanche
    path: {
	fornecedores: '/fornecedores/', // EM_Provider
	docsComuns: '/docs-comuns/', // SOL_DocComum (validade)
	documentos: '/documentos/', // SOL_Documento (licitacaoId)
	processos: '/licitacoes/', // SOL_Processo
	cotacoes: '/cotacoes/',
	resultados: '/resultados/',
	icms: '/content/Opções ICMS/',
	frete: '/content/Opções Frete/',
	federais: '/content/Opções Impostos Federais/',
	files: '/files/',
	config: '/content/'
    },
	
    datepickerFormat: 'dd/MM/yyyy',
    timepickerFormat: 'HH:mm',
    datetimeFormat: 'dd/MM/yyyy HH:mm',
    dateFormat: 'dd/MM/yyyy',

    discardTimeout: 5000,
    deleteTimeout: 5000,
    finalizarTimeout: 5000,
    saveDocHistory: true,

    cepConfig: {
	cidade: 'cidade_codigo',
	estado: 'uf_codigo',
	logradouro: 'logradouro',
	bairro: 'bairro'
    },

    searchCriteria: {
	processos: 'objeto,processo,tipoEdital,edital,codOrgao,orgao,cidade,estado,endereco,telefones,fax,observacoes,dataSessao,nroConlicitacao,nroBec,id_numero,id_ano',
	fornecedores: 'cnpj,ie,fantasyName,corporateName,postalCode,logradouro,number,numberAux,postOfficeBox,bairro,telephone,fax,email,website,contact,uf_codigo,cidade_codigo',
	docsComuns: 'dataEmissao,validade,nome',
	buscaArquivosProcesso: 'nome'
    }
});
